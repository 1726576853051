import styled from 'styled-components';

export const ButtonActive = styled.button`
  color: #000;
  text-align: center;
  background-color: #abcc3d;
  width: 220px;
  height: 50px;
  border-radius: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  :hover {
    transition: 0.5s;
    background-color: #8dad22;
  }
`;

export const ButtonUnActive = styled.button`
  color: #fff;
  text-align: center;
  background-color: #bababa;
  width: 220px;
  height: 50px;
  border-radius: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
`;

export const ButtonRepeat = styled.button`
  color: #000;
  text-align: center;
  background-color: #fff;
  border: 2px solid #abcc3d;
  width: 220px;
  height: 50px;
  border-radius: 50px;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  :hover {
    transition: 0.5s;
    border: 2px solid #8dad22;
  }
`;
