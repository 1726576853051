import React from 'react';
import {
  StepsStatus,
  AccordionHeaderBlock,
  StatusCheckBlock,
  StepsTitle,
} from './style';
import noPassIcon from '../../img/icon/notpass.svg';
import passIcon from '../../img/icon/pass.svg';
import checkDownIcon from '../../img/icon/check-down.svg';

function AccordionHeader(props) {
  return (
    <AccordionHeaderBlock>
      <StepsTitle>
        <div>
          <img
            src={props.stepInfo.is_passed_by_user ? passIcon : noPassIcon}
            alt=''
          />
        </div>
        {props.stepInfo.title}
      </StepsTitle>
      <StatusCheckBlock>
        <StepsStatus>
          <img
            src={props.stepInfo.is_passed_by_user ? passIcon : noPassIcon}
            alt=''
          />
          {props.stepInfo.is_passed_by_user ? 'Шаг пройден' : 'Шаг не пройден'}
        </StepsStatus>
        <div>
          <img
            style={{
              transform:
                props.selectedAccordId.indexOf(props.accordId) !== -1
                  ? 'rotate(180deg)'
                  : 'none',
            }}
            src={checkDownIcon}
            alt=''
          />
        </div>
      </StatusCheckBlock>
    </AccordionHeaderBlock>
  );
}

export default AccordionHeader;
