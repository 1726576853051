const initialState = {
  userAuth: false,
  can_start_exam_test: false,
  level: null,
  currentCertificate: null,
};

// eslint-disable-next-line default-param-last
export default function user(state = initialState, action) {
  const newState = {};
  Object.assign(newState, state);
  switch (action.type) {
    case 'CHECK_AUTH':
      // eslint-disable-next-line no-case-declarations
      const token = localStorage.getItem('token');
      if (token) {
        newState.userAuth = true;
      }
      return newState;
    case 'USER_HANDLER_AUTH':
      newState.userAuth = true;
      localStorage.setItem('token', action.data.access);
      localStorage.setItem('refresh', action.data.refresh);
      return newState;
    case 'SET_DATA_PROFILE':
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const item in action.data) {
        newState[item] = action.data[item];
      }
      return newState;
    case 'SET_CURRENT_CERTIFICATE':
      newState.currentCertificate = {};
      newState.currentCertificate.type = action.data.type;
      newState.currentCertificate.value = action.data.value;
      newState.currentCertificate.html = action.data.html;
      return newState;
    default:
      return state;
  }
}
