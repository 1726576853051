import styled from 'styled-components';

export const headerHeight = '100px';
export const headerHeightMobile = '60px';

export const HeaderStyled = styled.header`
  height: ${headerHeight};
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 550px) {
    height: ${headerHeightMobile};
  }
`;

export const Logo = styled.div`
  @media (max-width: 550px) {
    img {
      width: 140px;
    }
  }
`;

export const HeaderContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 0;
  @media (max-width: 550px) {
  }
`;

export const HeaderUserButton = styled.div`
  cursor: pointer;
  background-color: #abcc3d;
  color: #000000;
  padding: 12.5px 32px;
  border-radius: 50px;
  @media (max-width: 550px) {
    display: none;
  }
  :hover {
    transition: 0.5s;
    background-color: #8dad22;
  }
  img {
    margin-right: 18px;
  }
`;

export const HeaderUserButtonMobile = styled.div`
  display: none;
  @media (max-width: 550px) {
    display: inline-block;
  }
`;

export const HeaderUserBlock = styled.div`
  cursor: pointer;
  color: #000000;
  display: flex;
  flex-direction: row;
  align-items: center;
  img:first-child {
    margin-right: 40px;
    @media (max-width: 550px) {
      margin-right: 20px;
    }
  }
  span {
    color: #979797;
    margin-left: 16px;
    @media (max-width: 550px) {
      display: none;
    }
  }
`;

export const Notification = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 13px;
  height: 13px;
  background-color: #ffce23;
  border-radius: 50%;
`;

export const Tooltip = styled.div`
  position: absolute;
  width: 200px;
  top: 50px;
  right: 0;
  z-index: 99;
  padding: 20px;
  background-color: #b5d1ff;
  border-radius: 10px;
  ::after {
    content: '';
    position: absolute;
    top: -20px;
    left: 55%;
    transform: translateX(50%);
    border: 10px solid transparent;
    border-bottom: 10px solid #b5d1ff;
    z-index: 1;
  }
`;
