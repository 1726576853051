import React from 'react';
import moment from 'moment';
import {
  AccordionBlock,
  CertificateLink,
  AccordionCertificateBlock,
} from './style';
import clipIcon from '../../img/icon/file-new.svg';
import { ButtonActive } from '../../shared/Buttons';
import LinkToCertificate from '../LinkToCertificate/LinkToCertificate';

function AccordionContent(props) {
  let canStartTest = true;
  let levelText = 'Уровень не присвоен';
  if (!props.levelInfo.can_start_level_test) {
    canStartTest = false;
  }
  if (props.levelInfo.completed_at !== null) {
    levelText = `Уровень присвоен ${moment(props.levelInfo.completed_at).format(
      'DD.MM.YYYY'
    )}`;
  }
  return (
    <AccordionBlock>
      <p>{levelText}</p>
      {props.levelInfo.certificate_html !== null && (
        <AccordionCertificateBlock>
          <CertificateLink>
            <img alt='' src={clipIcon} />
            <LinkToCertificate
              type='level'
              value={props.levelInfo.title}
              html={props.levelInfo.certificate_html}
            >
              Скачать сертификат
            </LinkToCertificate>
          </CertificateLink>
        </AccordionCertificateBlock>
      )}
      {props.levelInfo.certificate !== null &&
        props.levelInfo.certificate_html === null && (
          <AccordionCertificateBlock>
            <CertificateLink>
              <img alt='' src={clipIcon} />
              <a href={props.levelInfo.certificate}>Скачать сертификат</a>
            </CertificateLink>
          </AccordionCertificateBlock>
        )}
      {props.levelInfo.certificate === null &&
        props.levelInfo.certificate_html === null &&
        canStartTest && (
          <ButtonActive
            data-test='level'
            data-content={props.levelInfo.title}
            data-start={canStartTest ? 1 : 0}
            id={props.levelInfo.pk}
            onClick={props.openConfirm}
            className='start-level-test'
          >
            Сдать тест на уровень
          </ButtonActive>
        )}
    </AccordionBlock>
  );
}

export default AccordionContent;
