import styled from 'styled-components';

export const AdvantagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  h2 {
    font-family: Manrope-ExtraBold;
    font-weight: 800;
    font-size: 40px;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 80px;
    span {
      color: #abcc3d;
    }
  }
  @media (max-width: 550px) {
    h2 {
      margin-top: 40px;
      margin-bottom: 40px;
      font-size: 26px;
    }
  }
`;

export const AdvantagesBlock = styled.section`
  width: 1144px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 100px;
  @media (max-width: 550px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding-bottom: 60px;
  }
`;

export const AdvantagesItem = styled.div`
  font-family: 'Manrope-Regular';
  font-weight: 600;
  width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  img {
    width: 80px;
    height: auto;
    margin-bottom: 24px;
  }
  @media (max-width: 550px) {
    width: 100%;
    :nth-child(2) {
      margin: 0;
    }
  }
`;

export const AdvantagesItemDescription = styled.div`
  text-align: center;
`;
