import styled from 'styled-components';

export const ModalBlock = styled.div`
  font-family: Manrope-Regular;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  p {
    position: relative;
  }
  .margin_bottom {
    margin-bottom: 40px;
  }
  #title {
    font-family: Manrope-ExtraBold;
    font-size: 30px;
    text-align: center;
  }
  @media (max-width: 550px) {
    padding: 30px;
  }
`;

export const Label = styled.div`
  position: absolute;
  top: -25px;
  left: 0;
  border-radius: 5px;
  color: #979797;
`;

export const Input = styled.input`
  width: 300px;
  height: 50px;
  background-color: #f2f7ff;
  border: none;
  padding: 0 15px;
  :focus {
    outline: none;
    border: 1px solid #8fbaff;
    border-radius: 5px;
  }
`;

export const ConfirmButtonBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
