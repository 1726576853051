import styled from 'styled-components';

export const TopicBlock = styled.section`
  color: #ffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0 6px 24px rgba(75, 101, 203, 0.15);
  padding: 60px 40px;
  margin-bottom: 30px;
  @media (max-width: 550px) {
    padding: 30px 10px;
  }
  h2 {
    font-family: 'Manrope-Regular';
    font-weight: 700;
    font-size: 32px;
    text-align: start;
    margin: 0 20px 10px 20px;
    @media (max-width: 550px) {
      font-size: 26px;
    }
  }
`;

export const List = styled.div`
  color: #ffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const MobileStatus = styled.div`
  div {
    display: none;
  }
  @media (max-width: 550px) {
    display: flex;
    flex-direction: row;
    img {
      margin-right: 12px;
    }
    div {
      display: block;
    }
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 50px;
  :hover {
    transition: 0.5s;
    background-color: #f4f8ff;
  }
  p {
    margin: 0;
    cursor: pointer;
  }
  a {
    font-size: 22px;
    font-weight: 600;
    color: #000000;
  }
  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
    a {
      text-align: start;
    }
  }
`;

export const ListItemStatusBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 550px) {
    margin-top: 20px;
  }
`;

export const ListItemStatus = styled.div`
  color: #979797;
  margin-right: 50px;
  width: 182px;
  img {
    margin-right: 5px;
  }
  @media (max-width: 550px) {
    display: none;
  }
`;

export const TopicExamButtonBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 520px;
  h3 {
    font-family: 'Manrope-ExtraBold';
    font-weight: 800;
    font-size: 40px;
  }
  span {
    margin-top: 25px;
    color: #ff7963;
  }
`;

export const TopicExamBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 100px;
  @media (max-width: 550px) {
    img {
      display: none;
    }
    h3 {
      font-size: 30px;
    }
  }
`;
