import OtherService from '../services/OtherService';

const initialState = {
  tests: [],
  themes: [],
  currentTopic: null,
  currentTopicSteps: null,
  levels: [],
  currentTest: {
    title: null,
    next_question: null,
    motivation: null,
    answer_list: [],
    timeout: null,
  },
};

// eslint-disable-next-line default-param-last
export default function tests(state = initialState, action) {
  const newState = {};
  Object.assign(newState, state);
  switch (action.type) {
    case 'SET_TESTS_DATA':
      newState.tests = action.data;
      return newState;
    case 'SET_THEMES_DATA':
      newState.themes = action.data;
      return newState;
    case 'SET_CURRENT_TOPIC_DATA':
      newState.currentTopic = action.data.topic;
      newState.currentTopicSteps = action.data.steps;
      return newState;
    case 'SET_LEVELS_DATA':
      newState.levels = action.data;
      return newState;
    case 'SET_CURRENT_TEST_DATA':
      newState.currentTest = action.data;
      return newState;
    case 'SET_NEW_QUESTION_TEST_DATA':
      if (!OtherService.isEmpty(action.data.next_question)) {
        newState.currentTest.next_question = action.data.next_question;
      } else {
        newState.currentTest.next_question = 'end';
      }
      if (newState.currentTest.type === 'step') {
        newState.currentTest.qCount = action.data.answer_count;
      } else {
        // eslint-disable-next-line no-plusplus
        ++newState.currentTest.qCount;
      }

      newState.currentTest.motivation = action.data.motivation;
      newState.currentTest.answer_list = action.data.answer_list;
      newState.currentTest.status = action.data.status;
      return newState;
    case 'SET_NEW_QUESTION_END':
      newState.currentTest.next_question = 'end';
      return newState;
    case 'RESET_CURRENT_TEST_DATA':
      newState.currentTest = {
        title: null,
        next_question: null,
        motivation: null,
        answer_list: [],
        timeout: null,
      };
      return newState;
    default:
      return state;
  }
}
