import styled from 'styled-components';
import { Container } from '../../shared/Container';

export const BackToTopics = styled.div`
  cursor: pointer;
  margin-bottom: 40px;
  img {
    margin-right: 15px;
  }
`;

export const ContainerForTestBlock = styled(Container)`
  padding: 60px;
  box-shadow: 0 6px 24px rgba(0, 37, 184, 0.15);
  border-radius: 27px;
  margin-bottom: 150px;
  h2 {
    padding: 60px 60px 0 60px;
    font-size: 32px;
    @media (max-width: 550px) {
      text-align: center;
    }
  }
  @media (max-width: 550px) {
    padding: 30px 24px;
  }
`;

export const TestHeaderBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

export const MotivationDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 550px) {
    img {
      width: 100%;
      height: auto;
    }
    padding: 25px;
    flex-direction: column-reverse;
  }
`;

export const MotivationButtonTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-family: Manrope-ExtraBold;
    font-size: 40px;
  }
  @media (max-width: 550px) {
    text-align: center;
    align-items: center;
  }
`;

export const TestTime = styled.div`
  font-size: 35px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  div {
    background-color: #e5efff;
    border-radius: 8px;
    padding: 8px;
  }
  @media (max-width: 550px) {
    margin-left: 0;
    margin-bottom: 25px;
  }
`;

export const QuestionCounter = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  div {
    color: #727272;
  }
  img {
    cursor: pointer;
    display: inline-block;
    margin-left: auto;
  }
`;

export const ProgressTest = styled.progress`
  width: 100%;
  height: 19px;
  background-color: #f2f7ff;
  color: #abcc3d;
  border-radius: 20px;
  border: none;
  ::-moz-progress-bar {
    background-color: #abcc3d;
    border-radius: 20px;
  }
  ::-webkit-progress-bar {
    border-radius: 20px;
    background-color: #f2f7ff;
  }
  ::-webkit-progress-value {
    background-color: #abcc3d;
    border-radius: 20px;
  }
`;

export const QuestionTitle = styled.div`
  font-family: Manrope-ExtraBold;
  font-weight: 800;
  font-size: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  @media (max-width: 550px) {
    font-family: Manrope-Regular;
    font-weight: 700;
    font-size: 26px;
  }
`;

export const QuestionDescription = styled.div`
  color: #2e2e2e;
`;

export const AnswerBlock = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 40px;
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
`;

export const AnswerItem = styled.div`
  padding: 30px;
  border: 2px solid #dcdfe6;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  min-height: 200px;
  :hover {
    transition: 0.5s;
    border: 2px solid #8fbaff;
  }
`;

export const AnswerButtons = styled.div`
  @media (max-width: 550px) {
    button {
      width: 100%;
    }
  }
`;

export const StatisticBlock = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0 60px;
  @media (max-width: 550px) {
    width: 100%;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 24px;
    padding: 0 30px;
  }
`;

export const StatisticColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  @media (max-width: 550px) {
    align-items: center;
    text-align: center;
  }
`;

export const CircularProgressbarBlock = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    width: 170px;
    font-size: 18px;
  }
`;

export const StatisticRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  p {
    width: 135px;
    @media (max-width: 550px) {
      text-align: left;
    }
  }
  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 65px;
    height: 65px;
    text-align: center;
    font-size: 35px;
    font-family: Manrope-ExtraBold;
    font-weight: 800;
    background-color: #ff4d4f;
    color: #fff;
    border-radius: 8px;
  }
`;

export const CircularBlock = styled.div`
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 5px solid;
  p {
    font-size: 22px;
  }
`;

export const LinkBlockOne = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 150px;
  button {
    width: 295px;
  }
`;

export const StatusBlock = styled.div`
  width: 100%;
  height: 105px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #f4f8ff;
  margin-top: 50px;
  div {
    font-size: 22px;
    min-width: 170px;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    color: #000;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  img {
    transform: scale(2);
    margin-right: 20px;
  }
`;

export const CertificateBlock = styled.p`
  display: flex;
  flex-direction: row;
`;
