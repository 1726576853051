import styled from 'styled-components';

export const TopicHeaderBlock = styled.div`
  img {
    margin-right: 15px;
  }
  a {
    color: #374149;
  }
  div {
    margin: 40px 0 30px 0;
    @media (max-width: 550px) {
      margin: 30px 0 20px 0;
    }
  }
  h1 {
    max-width: 650px;
    @media (max-width: 550px) {
      font-weight: 700;
    }
  }
`;

export const TopicDescription = styled.div`
  max-width: 1050px;
  margin-bottom: 40px;
`;

export const AccessTopicBlock = styled.div`
  font-family: Manrope-ExtraBold;
  font-weight: 800;
  color: #2e2e2e;
  margin-bottom: 40px;
  padding: 40px;
  border-radius: 19px;
  background-color: #e5efff;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
  }
  div:last-child {
    max-width: 860px;
  }
  img {
    margin-right: 40px;
    @media (max-width: 550px) {
      margin-bottom: 25px;
    }
  }
`;

export const StepsBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-shadow: 0 6px 24px rgba(0, 37, 184, 0.15);
  border-radius: 10px;
  h2 {
    font-weight: 700;
    font-size: 32px;
    padding: 0 30px;
  }
`;

export const AccordionStepBlock = styled.div`
  padding: 30px;
  border-radius: 10px;
  @media (max-width: 550px) {
    padding: 15px;
  }
  :hover {
    transition: 0.5s;
    background-color: #f4f8ff;
  }
`;

export const AccordionHeaderBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 22px;
  cursor: pointer;
`;

export const StepsTitle = styled.div`
  display: flex;
  flex-direction: row;
  img {
    display: none;
  }
  @media (max-width: 550px) {
    img {
      display: inline-block;
      margin-right: 20px;
    }
  }
`;

export const StatusCheckBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

export const StepsStatus = styled.div`
  color: #979797;
  min-width: 150px;
  img {
    margin-right: 10px;
  }
  @media (max-width: 550px) {
    display: none;
  }
`;

export const AccordionBlock = styled.div`
  margin-top: 20px;
  @media (max-width: 550px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;
