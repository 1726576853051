import 'antd/dist/antd.css';
import { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import women from '../../img/main-page/woman.png';
import womenMobile from '../../img/main-page/woman-mobile.png';
import Header from '../Header/Header';
import checkIcon from '../../img/icon/check-new.svg';
import Advantages from './Advantages/Advantages';
import Convenient from './Convenient/Convenient';
import Footer from '../Footer/Footer';
import ModalAuth from '../ModalAuth/ModalAuth';
import { userHandlerAuth } from '../../actions/user';
import { Container } from '../../shared/Container';
import {
  BackGroundInfoBlock,
  CheckList,
  InfoImgBlock,
  Info,
  ImgDesktop,
  ImgMobile,
} from './style';
import { MainIndex } from '../../shared/Main';

function MainPage(props) {
  const [open, openModal] = useState(false);

  const openModalHandler = () => {
    openModal(!open);
  };

  // eslint-disable-next-line no-shadow
  const userHandlerAuth = (data) => {
    props.onUserHandlerAuth(data);
  };

  const openThemesPage = () => {
    props.history.push(`/topics`);
  };

  return (
    <div className='wrapper'>
      <Header onClickUserIcon={openModalHandler} />
      <MainIndex>
        <BackGroundInfoBlock>
          <Container>
            <InfoImgBlock>
              <Info>
                <h1>
                  Самый популярный ресурс для{' '}
                  <span>самообразования и корпоративного обучения</span>
                </h1>
                <CheckList>
                  <div>
                    <img src={checkIcon} alt='' />
                    легко
                  </div>
                  <div>
                    <img src={checkIcon} alt='' />
                    эффективно
                  </div>
                  <div>
                    <img src={checkIcon} alt='' />
                    научно обосновано
                  </div>
                </CheckList>
                {/* eslint-disable-next-line react/button-has-type */}
                <button
                  style={{
                    width: props.tests.tests.length > 0 ? '300px' : '270px',
                  }}
                  onClick={
                    props.user.userAuth ? openThemesPage : openModalHandler
                  }
                >
                  {props.tests.tests.length > 0
                    ? 'Продолжить обучение'
                    : 'Начать обучение'}
                </button>
              </Info>
              <ImgDesktop>
                <img src={women} alt='' />
              </ImgDesktop>
            </InfoImgBlock>
          </Container>
          <ImgMobile>
            <img src={womenMobile} alt='' />
          </ImgMobile>
        </BackGroundInfoBlock>
        <Advantages />
        <Convenient />
      </MainIndex>
      <Footer />
      <ModalAuth
        open={open}
        openModalHandler={openModalHandler}
        responseHandler={userHandlerAuth}
      />
    </div>
  );
}

export default withRouter(
  connect(
    (state) => ({
      user: state.user,
      tests: state.tests,
    }),
    (dispatch) => ({
      onUserHandlerAuth: (data) => {
        dispatch(userHandlerAuth(data));
      },
    })
  )(MainPage)
);
