import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import styled from 'styled-components';
import Header from '../Header/Header';
import { Main } from '../../shared/Main';
import { Container } from '../../shared/Container';
import Footer from '../Footer/Footer';
import 'react-circular-progressbar/dist/styles.css';
import {
  TestHeaderBlock,
  TestTime,
  QuestionCounter,
  MotivationDescription,
  LinkBlockOne,
  StatusBlock,
  // CertificateBlock,
  BackToTopics,
  ContainerForTestBlock,
  QuestionTitle,
  QuestionDescription,
  AnswerBlock,
  AnswerItem,
  AnswerButtons,
  ProgressTest,
  MotivationButtonTitleBlock,
  StatisticBlock,
  StatisticColumn,
  CircularProgressbarBlock,
  StatisticRow,
  CircularBlock,
} from './style';
import { TopicDescription } from '../TopicsItemPage/style';
import hintIcon from '../../img/icon/hint.svg';
import './style.css';
import {
  getTestServer,
  sendAnswerServer,
  getProfileServer,
} from '../../actions/ajaxMiddleware';
import ModalAlert from '../ModalAlert/ModalAlert';
import rightIcon from '../../img/icon/success-big.svg';
import errorIcon from '../../img/icon/error-big.svg';
import checkIcon from '../../img/icon/pass.svg';
import questionsIcon from '../../img/icon/notpass.svg';
import OtherService from '../../services/OtherService';
import {
  resetCurrentTestData,
  setNewQuestionTestEnd,
} from '../../actions/tests';
import { AjaxService } from '../../services/AjaxService';
import arrowLong from '../../img/icon/arrow-long.svg';
import { ButtonActive, ButtonUnActive } from '../../shared/Buttons';
import LinkToCertificate from '../LinkToCertificate/LinkToCertificate';

function TestPassPage(props) {
  const { id, type } = props.match.params;
  const { onGetTestServer } = props;
  const { currentTest } = props.tests;
  const { motivation } = props.tests.currentTest;

  const [openHint, onOpenHint] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [motivationData, setMotivationData] = useState(null);
  const [testTitle, setTextTitle] = useState(null);
  const [statistic, setStatistic] = useState(null);
  const [testTimer, setTestTimer] = useState(null);
  const [time, changeTime] = useState(null);
  const [textButton, setTextButton] = useState('Выберите вариант ответа');

  const StatisticColumnChangeable = styled(StatisticColumn)`
    justify-content: ${currentTest.timeout ? 'space-between' : 'flex-start'};
  `;

  const hintHandler = () => {
    onOpenHint(!openHint);
  };

  const answerHandler = (e) => {
    const answerId = e.currentTarget.id;
    setAnswer(answerId);
  };

  const onAnswer = () => {
    if (textButton !== 'Отправка...') {
      setTextButton('Отправка...');
      const data = {
        answer,
        questionId: currentTest.next_question.pk,
        testId: currentTest.pk,
      };
      props.onSendAnswerServer(data);
      setAnswer(null);
    }
  };

  const goToTopics = () => {
    // eslint-disable-next-line no-use-before-define
    resetState();
    props.onResetCurrentTestData();
    props.onGetProfileServer();
    props.history.push(`/topics`);
  };

  const resetState = () => {
    if (testTimer !== null) {
      clearInterval(testTimer);
      setTestTimer(null);
    }
    onOpenHint(false);
    setAnswer(null);
    setMotivationData(null);
    setTextTitle(null);
    setStatistic(null);
    changeTime(null);
  };

  const timer = (endTime, dateStart) => {
    if (endTime > 0) {
      // eslint-disable-next-line no-shadow
      const timer = setInterval(() => {
        const delta = Date.now() - dateStart;
        let newEndTime = endTime - delta;
        if (newEndTime <= 0) {
          newEndTime = 0;
          changeTime(newEndTime);
          message.error('Время вышло');
          props.onSetNewQuestionTestEnd();
          clearInterval(testTimer);
        }
        changeTime(newEndTime);
      }, 1000);
      setTestTimer(timer);
    }
  };

  useEffect(() => {
    const dataTest = {
      type,
      id,
    };
    onGetTestServer(dataTest);
  }, [onGetTestServer, type, id]);

  const completeTest = (res) => {
    if (testTimer !== null) {
      clearInterval(testTimer);
      setTestTimer(null);
    }
    // eslint-disable-next-line no-shadow
    const statistic = {
      timeEnd: res.completed_at,
      duration: null,
      maxTime: null,
      requiredPercent: null,
      numberOfCorrectAnswers: null,
      correctPercent: null,
      numberOfUnCorrectAnswers: null,
      numberWithoutAnswers: null,
      meetTime: null,
      certificate_html: null,
      certificate: null,
      getCertificate: false,
      status: {
        title: null,
        passed: false,
        color: '',
      },
    };
    statistic.numberOfCorrectAnswers = res.right_question_count;
    statistic.numberOfUnCorrectAnswers = res.wrong_question_count;
    statistic.correctPercent = Math.round(Number(res.right_percentage));
    if (type === 'step') {
      statistic.status.passed = true;
      statistic.status.title = 'Шаг пройден';
      statistic.status.color = '#374149';
    } else {
      if (currentTest.timeout > 0) {
        statistic.maxTime = currentTest.timeout;
        if (res.is_on_time) {
          statistic.meetTime = true;
        } else {
          statistic.meetTime = false;
        }

        if (res.duration) {
          statistic.duration = {};
          const arrDuration = res.duration.split(':');
          // eslint-disable-next-line prefer-destructuring
          statistic.duration.minutes = arrDuration[1];
          // eslint-disable-next-line prefer-destructuring
          statistic.duration.seconds = arrDuration[2].split('.')[0];
        }
      }
      if (currentTest.required_percentage) {
        statistic.requiredPercent = Math.round(
          Number(currentTest.required_percentage)
        );
      }
      statistic.numberWithoutAnswers = res.unanswered_question_count;
      switch (type) {
        case 'exam':
          if (res.status === 'failed') {
            statistic.status.passed = false;
            statistic.status.title = 'Экзамен не сдан';
            statistic.status.color = '#374149';
            statistic.certificate = 'не получен';
          } else {
            statistic.status.passed = true;
            statistic.status.title = 'Экзамен сдан';
            statistic.status.color = '#34920c';
          }
          if (res.certificate_html !== null) {
            statistic.getCertificate = true;
            statistic.certificate_html = res.certificate_html;
          } else if (res.certificate !== null) {
            statistic.certificate = res.certificate;
            statistic.getCertificate = true;
          }
          break;
        case 'level':
          if (res.status === 'failed') {
            statistic.status.passed = false;
            statistic.status.title = 'Уровень не получен';
            statistic.status.color = '#374149';
            statistic.certificate = 'не получен';
          } else {
            statistic.status.passed = true;
            statistic.status.title = 'Уровень получен';
            statistic.status.color = '#34920c';
          }
          if (res.certificate_html !== null) {
            statistic.getCertificate = true;
            statistic.certificate_html = res.certificate_html;
          } else if (res.certificate !== null) {
            statistic.certificate = res.certificate;
            statistic.getCertificate = true;
          }
          break;
        default:
          if (res.status === 'failed') {
            statistic.status.passed = false;
            statistic.status.title = 'Тема не пройдена';
            statistic.status.color = '#374149';
          } else {
            statistic.status.passed = true;
            statistic.status.title = 'Тема сдана';
            statistic.status.color = '#34920c';
          }
      }
    }
    setStatistic(statistic);
  };

  useEffect(() => {
    setTextButton('Отправить ответ');
    if (currentTest.next_question === 'end') {
      if (time !== 0) {
        AjaxService.ajaxGet(`/tests/${currentTest.pk}/`).then((res) => {
          completeTest(res);
        });
      } else {
        const data = {};
        AjaxService.ajaxPost(
          `/tests/${currentTest.pk}/set-expired/`,
          data
        ).then((res) => {
          completeTest(res);
        });
      }
    }
  }, [currentTest.next_question, setStatistic, type, motivationData]);

  const closeMotivation = () => {
    setMotivationData(null);
  };

  useEffect(() => {
    if (motivation !== null) {
      if (motivation.show_on_separate_screen) {
        setMotivationData(motivation);
      } else {
        // eslint-disable-next-line no-unused-expressions
        motivation.typeof === 'right'
          ? message.success(motivation.title)
          : message.error(motivation.title);
      }
    }
  }, [motivation]);

  useEffect(() => {
    if (currentTest.title !== null) {
      let diff = 0;
      if (currentTest.timeout) {
        diff = moment().diff(currentTest.created_at, 'minutes');
      }
      switch (type) {
        case 'step':
          setTextTitle(currentTest.title);
          break;
        case 'topic':
          setTextTitle(`Тест по теме: ${currentTest.title}`);
          timer(Number((currentTest.timeout - diff) * 60000), Date.now());
          break;
        case 'level':
          setTextTitle(`Тест на уровень: ${currentTest.title}`);
          timer(Number((currentTest.timeout - diff) * 60000), Date.now());
          break;
        default:
          setTextTitle(`Экзамен`);
          timer(Number((currentTest.timeout - diff) * 60000), Date.now());
      }
    }
  }, [currentTest.title, type]);

  useEffect(
    () => () => {
      resetState();
      props.onResetCurrentTestData();
    },
    []
  );
  return (
    <div className='wrapper'>
      <Header />
      <Main>
        {currentTest.next_question !== null &&
        currentTest.next_question !== 'end' ? (
          <>
            <Container>
              <BackToTopics onClick={goToTopics}>
                <img src={arrowLong} alt='' /> назад к списку тем
              </BackToTopics>
              <TestHeaderBlock>
                <h1 className='main-header'>{testTitle}</h1>
                {type !== 'step' && time !== null ? (
                  <TestTime
                    style={{ color: time <= 300000 ? '#920c0c' : '#000000' }}
                  >
                    <div>{OtherService.msToTimeMinutes(time)}</div>:
                    <div>{OtherService.msToTimeSeconds(time)}</div>
                  </TestTime>
                ) : (
                  ''
                )}
              </TestHeaderBlock>
            </Container>
            {motivationData === null ? (
              <ContainerForTestBlock>
                <TopicDescription>
                  <QuestionCounter>
                    <div>
                      {type === 'step' ? 'Правильных ответов' : 'Вопрос'}{' '}
                      {currentTest.qCount} из {currentTest.question_count}
                    </div>
                    {type === 'step' && currentTest.next_question.hint ? (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                      <img
                        src={hintIcon}
                        alt=''
                        title='Посмотреть подсказку'
                        onClick={hintHandler}
                      />
                    ) : (
                      ''
                    )}
                  </QuestionCounter>
                  <ProgressTest
                    max={currentTest.question_count}
                    value={currentTest.qCount}
                  />
                  <QuestionTitle>
                    {type === 'step' ? 'Вопрос: ' : ''}
                    {currentTest.next_question.title}
                  </QuestionTitle>
                  <QuestionDescription>
                    {currentTest.next_question.description}
                  </QuestionDescription>
                </TopicDescription>
                <AnswerBlock>
                  {currentTest.next_question.variants.map((item) => (
                    <AnswerItem
                      id={item.pk}
                      style={{
                        backgroundColor:
                          Number(answer) === item.pk ? '#F2F7FF' : '#fff',
                      }}
                      onClick={answerHandler}
                    >
                      {item.title}
                    </AnswerItem>
                  ))}
                </AnswerBlock>
                <AnswerButtons>
                  {answer !== null ? (
                    <ButtonActive onClick={onAnswer}>
                      Отправить ответ
                    </ButtonActive>
                  ) : (
                    <ButtonUnActive>{textButton}</ButtonUnActive>
                  )}
                </AnswerButtons>
              </ContainerForTestBlock>
            ) : (
              <ContainerForTestBlock>
                <MotivationDescription>
                  <MotivationButtonTitleBlock>
                    <p>{motivationData.title}</p>
                    <ButtonActive onClick={closeMotivation}>
                      {motivationData.typeof === 'right'
                        ? 'Продолжить'
                        : 'Я понял'}
                    </ButtonActive>
                  </MotivationButtonTitleBlock>
                  <p>
                    <img
                      src={
                        motivationData.typeof === 'right'
                          ? rightIcon
                          : errorIcon
                      }
                      alt=''
                    />
                  </p>
                </MotivationDescription>
              </ContainerForTestBlock>
            )}
            <ModalAlert
              open={openHint}
              onClose={hintHandler}
              title='Подсказка'
              content={currentTest.next_question.hint}
              variant='prompt'
            />
          </>
        ) : currentTest.next_question === 'end' && motivationData === null ? (
          <>
            <Container>
              <BackToTopics onClick={goToTopics}>
                <img src={arrowLong} alt='' /> назад к списку тем
              </BackToTopics>
              <TestHeaderBlock>
                <h1 className='main-header'>{testTitle}</h1>
              </TestHeaderBlock>
            </Container>
            {statistic !== null ? (
              <ContainerForTestBlock style={{ padding: 0, marginBottom: 0 }}>
                <h2>Статистика:</h2>
                <StatisticBlock>
                  <StatisticColumnChangeable>
                    <div>
                      время начала: <br />
                      <span
                        style={{
                          fontSize: 22,
                          fontWeight: 500,
                        }}
                      >
                        {moment(currentTest.created_at).format('DD.MM.YYYY')}
                      </span>{' '}
                      <span
                        style={{
                          fontSize: 22,
                          fontWeight: 700,
                        }}
                      >
                        {moment(currentTest.created_at).format('HH:mm:ss')}
                      </span>
                    </div>
                    <div>
                      время окончания:: <br />
                      <span
                        style={{
                          fontSize: 22,
                          fontWeight: 500,
                        }}
                      >
                        {moment(statistic.timeEnd).format('DD.MM.YYYY')}
                      </span>{' '}
                      <span
                        style={{
                          fontSize: 22,
                          fontWeight: 700,
                        }}
                      >
                        {moment(statistic.timeEnd).format('HH:mm:ss')}
                      </span>
                    </div>
                    {statistic.duration !== null && (
                      <>
                        Длительность:
                        <TestTime
                          style={{
                            marginLeft: 0,
                          }}
                        >
                          <div>{statistic.duration.minutes}</div>:
                          <div>{statistic.duration.seconds}</div>
                        </TestTime>
                      </>
                    )}
                    {statistic.maxTime !== null && (
                      <>
                        Максимальное время прохождения:
                        <TestTime
                          style={{
                            marginLeft: 0,
                          }}
                        >
                          <div>{statistic.maxTime}</div>:<div>00</div>
                        </TestTime>
                      </>
                    )}
                  </StatisticColumnChangeable>
                  {type !== 'step' && (
                    <StatisticColumn>
                      {statistic.requiredPercent !== null && (
                        <CircularProgressbarBlock>
                          <p>Требуемый процент успешных ответов:</p>
                          <div
                            style={{
                              width: 120,
                              fontFamily: 'Manrope-ExtraBold',
                              fontWeight: 800,
                            }}
                          >
                            <CircularProgressbar
                              value={statistic.requiredPercent}
                              text={`${statistic.requiredPercent}%`}
                              styles={buildStyles({
                                rotation: 0.5,
                                pathColor: `#ABCC3D`,
                                textColor: '#2E2E2E',
                                trailColor: '#E5EFFF',
                                textSize: '30px',
                              })}
                            />
                          </div>
                        </CircularProgressbarBlock>
                      )}
                      {statistic.correctPercent !== null && (
                        <CircularProgressbarBlock>
                          <p>Процент верных ответов</p>
                          <div
                            style={{
                              width: 120,
                              fontFamily: 'Manrope-ExtraBold',
                              fontWeight: 800,
                            }}
                          >
                            <CircularProgressbar
                              value={statistic.correctPercent}
                              text={`${statistic.correctPercent}%`}
                              styles={buildStyles({
                                rotation: 0.5,
                                pathColor: `#FF8A1F`,
                                textColor: '#2E2E2E',
                                trailColor: '#E5EFFF',
                                textSize: '30px',
                              })}
                            />
                          </div>
                        </CircularProgressbarBlock>
                      )}
                    </StatisticColumn>
                  )}
                  <StatisticColumn>
                    <StatisticRow>
                      <p>Общее количество вопросов</p>
                      <div style={{ backgroundColor: '#91C0E2' }}>
                        <span>{currentTest.question_count}</span>
                      </div>
                    </StatisticRow>
                    {statistic.numberOfCorrectAnswers !== null && (
                      <StatisticRow>
                        <p>Количество верных ответов</p>
                        <div style={{ backgroundColor: '#ABCC3D' }}>
                          <span>{statistic.numberOfCorrectAnswers}</span>
                        </div>
                      </StatisticRow>
                    )}
                    {statistic.numberOfUnCorrectAnswers !== null && (
                      <StatisticRow>
                        <p>Количество неверных ответов</p>
                        <div style={{ backgroundColor: '#ABCC3D' }}>
                          <span>{statistic.numberOfUnCorrectAnswers}</span>
                        </div>
                      </StatisticRow>
                    )}
                    {statistic.numberWithoutAnswers !== null && (
                      <StatisticRow>
                        <p>Количество вопросов без ответа ответов</p>
                        <div style={{ backgroundColor: '#F27C68' }}>
                          <span>{statistic.numberWithoutAnswers}</span>
                        </div>
                      </StatisticRow>
                    )}
                  </StatisticColumn>
                  <StatisticColumn>
                    {statistic.meetTime !== null && (
                      <CircularProgressbarBlock>
                        <p>Уложился по времени</p>
                        <CircularBlock
                          style={{
                            borderColor: statistic.meetTime
                              ? '#ABCC3D'
                              : '#F27C68',
                          }}
                        >
                          <span>{statistic.meetTime ? 'Да' : 'Нет'}</span>
                        </CircularBlock>
                      </CircularProgressbarBlock>
                    )}
                    {(type === 'exam' || type === 'level') && (
                      <CircularProgressbarBlock>
                        <p>Сертификат</p>
                        <CircularBlock
                          style={{
                            borderColor: statistic.getCertificate
                              ? '#ABCC3D'
                              : '#F27C68',
                          }}
                        >
                          <div>
                            {statistic.getCertificate ? (
                              statistic.certificate_html ? (
                                <LinkToCertificate
                                  type={type}
                                  html={statistic.certificate_html}
                                  value={currentTest.title}
                                >
                                  Скачать
                                </LinkToCertificate>
                              ) : (
                                <a
                                  style={{ color: '#000' }}
                                  target='_blank'
                                  rel='noreferrer'
                                  href={statistic.certificate}
                                >
                                  Скачать
                                </a>
                              )
                            ) : (
                              statistic.certificate
                            )}
                          </div>
                        </CircularBlock>
                      </CircularProgressbarBlock>
                    )}
                  </StatisticColumn>
                </StatisticBlock>
                <StatusBlock>
                  <div>
                    <img
                      src={statistic.status.passed ? checkIcon : questionsIcon}
                      alt=''
                    />
                    {statistic.status.title}
                  </div>
                </StatusBlock>
              </ContainerForTestBlock>
            ) : (
              ''
            )}
            <LinkBlockOne>
              <ButtonActive onClick={goToTopics}>
                Вернуться к списку тем
              </ButtonActive>
            </LinkBlockOne>
          </>
        ) : motivationData !== null ? (
          <>
            <Container>
              <TestHeaderBlock>
                <h1 className='main-header'>{testTitle}</h1>
                {type !== 'step' && time !== null ? (
                  <TestTime
                    style={{ color: time <= 300000 ? '#920c0c' : '#000000' }}
                  >
                    <div>{OtherService.msToTimeMinutes(time)}</div>:
                    <div>{OtherService.msToTimeSeconds(time)}</div>
                  </TestTime>
                ) : (
                  ''
                )}
              </TestHeaderBlock>
            </Container>
            <ContainerForTestBlock>
              <MotivationDescription>
                <MotivationButtonTitleBlock>
                  <p>{motivationData.title}</p>
                  <ButtonActive onClick={closeMotivation}>
                    {motivationData.typeof === 'right'
                      ? 'Продолжить'
                      : 'Я понял'}
                  </ButtonActive>
                </MotivationButtonTitleBlock>
                <p>
                  <img
                    src={
                      motivationData.typeof === 'right' ? rightIcon : errorIcon
                    }
                    alt=''
                  />
                </p>
              </MotivationDescription>
            </ContainerForTestBlock>
          </>
        ) : (
          ''
        )}
      </Main>
      <Footer />
    </div>
  );
}

export default withRouter(
  connect(
    (state) => ({
      user: state.user,
      tests: state.tests,
    }),
    (dispatch) => ({
      onGetTestServer: (data) => {
        dispatch(getTestServer(data));
      },
      onSendAnswerServer: (data) => {
        dispatch(sendAnswerServer(data));
      },
      onResetCurrentTestData: () => {
        dispatch(resetCurrentTestData());
      },
      onGetProfileServer: () => {
        dispatch(getProfileServer());
      },
      onSetNewQuestionTestEnd: () => {
        dispatch(setNewQuestionTestEnd());
      },
    })
  )(TestPassPage)
);
