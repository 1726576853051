import React from 'react';
import { Status, AccordionHeaderBlock } from './style';
import noPassIcon from '../../img/icon/notpass.svg';
import passIcon from '../../img/icon/pass.svg';
import lockIcon from '../../img/icon/lock.svg';
import checkDownIcon from '../../img/icon/check-down.svg';

function AccordionHeader(props) {
  let icon = lockIcon;
  let text = 'Уровень недоступен';
  if (props.levelInfo.completed_at !== null) {
    icon = passIcon;
    text = 'Уровень сдан';
  } else if (props.levelInfo.can_start_level_test) {
    icon = noPassIcon;
    text = 'Уровень доступен';
  }
  return (
    <AccordionHeaderBlock>
      <div className='header-title'>
        <img src={icon} alt='' /> {props.levelInfo.title}
      </div>
      <Status>
        <div>
          <img src={icon} alt='' />
          {text}
        </div>
        <img
          style={{
            transform:
              props.selectedAccordId.indexOf(props.accordId) !== -1
                ? 'rotate(180deg)'
                : 'none',
          }}
          src={checkDownIcon}
          alt=''
        />
      </Status>
    </AccordionHeaderBlock>
  );
}

export default AccordionHeader;
