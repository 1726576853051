import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReactDOM from 'react-dom';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import App from './App';
import reducer from './reducers';
// import ajaxMiddleware from "./middleware/ajaxMiddleware";
// eslint-disable-next-line import/no-cycle
import mySaga from './middleware/saga';

export const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];
const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
sagaMiddleware.run(mySaga);

window.onload = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};
