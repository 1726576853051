import styled from 'styled-components';

export const HeaderProfile = styled.div`
  img {
    margin-right: 15px;
  }
  a {
    color: #374149;
  }
  h1 {
    margin: 40px 0;
  }
`;

export const ListLevelBlock = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  gap: 20px;
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const ListProfile = styled.div`
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: 0 6px 24px rgba(0, 37, 184, 0.15);
  border-radius: 10px;
  padding: 60px;
  h2 {
    font-weight: 700;
    font-size: 32px;
  }
  @media (max-width: 550px) {
    padding: 30px;
  }
`;

export const ListProfileGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  @media (max-width: 550px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const ListProfileItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  height: 50px;
  #field-password {
    position: relative;
    top: 10px;
    font-size: 32px;
  }
  span {
    position: absolute;
    top: -2px;
    left: 0;
    font-family: Manrope-Regular;
    font-weight: 400;
    color: #979797;
    font-size: 12px;
  }
  div {
    @media (max-width: 550px) {
      color: #8fbaff;
    }
  }
  img {
    cursor: pointer;
  }
`;

export const LevelBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 6px 24px rgba(0, 37, 184, 0.15);
  border-radius: 10px;
  padding: 40px;
  #level-title {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 70px;
    font-size: 18px;
  }
  .value-text {
    margin-top: 10px;
    font-size: 22px;
    font-weight: 700;
  }
  span {
    margin-top: 20px;
    color: #979797;
    font-size: 12px;
  }
`;

export const CertificateLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 22px;
  a {
    color: #abcc3d;
    margin-left: 5px;
  }
  div {
    color: #abcc3d;
    margin-left: 5px;
  }
`;

export const AccordionLevelsBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-shadow: 0 6px 24px rgba(0, 37, 184, 0.15);
  border-radius: 10px;
  margin-top: 15px;
  h2 {
    font-weight: 700;
    font-size: 32px;
    padding: 0 15px;
  }
`;

export const AccordionBlock = styled.div`
  p {
    margin-top: 20px;
  }
  a {
    margin-top: 30px;
  }
  .start-level-test {
    width: 300px;
  }
  @media (max-width: 550px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .start-level-test {
      width: 220px;
    }
  }
`;

export const AccordionCertificateBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  img {
    margin-right: 15px;
  }
  a {
    margin: 0;
  }
  div {
    align-items: center;
    justify-content: flex-start;
  }
`;

export const AccordionSection = styled.div`
  padding: 30px;
  border-radius: 10px;
  @media (max-width: 550px) {
    padding: 15px;
  }
  :hover {
    transition: 0.5s;
    background-color: #f4f8ff;
  }
`;

export const AccordionHeaderBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .header-title {
    font-size: 22px;
    font-weight: 600;
    img {
      display: none;
      @media (max-width: 550px) {
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
`;

export const Status = styled.div`
  display: flex;
  flex-direction: row;
  color: #979797;
  gap: 40px;
  div:first-child {
    width: 210px;
    img {
      margin-right: 10px;
    }
    @media (max-width: 550px) {
      display: none;
    }
  }
`;

export const LinksBlock = styled.div`
  width: 100%;
  box-shadow: 0 6px 24px rgba(0, 37, 184, 0.15);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 60px;
  margin-top: 15px;
  margin-bottom: 80px;
  cursor: pointer;
  font-size: 22px;
  color: #abcc3d;
  div {
    width: 100%;
  }
  svg {
    margin-right: 15px;
  }
  @media (max-width: 550px) {
    padding: 35px;
  }
`;
