import { Modal, message } from 'antd';
import { useState } from 'react';
import { AjaxService } from '../../services/AjaxService';
import {
  ModalAuthHeader,
  ModalAuthButton,
  Input,
  ModalAuthInputsBlock,
  Label,
  RegButtonText,
} from './style';

function ModalAuth(props) {
  const [reg, toggleAuthReg] = useState(false);
  const [email, changeEmail] = useState('');
  const [password, changePassword] = useState('');
  const [repeatPassword, changeRepeatPassword] = useState('');
  const [buttonText, changeButtonText] = useState('Войти');

  const onToggleReg = () => {
    toggleAuthReg(true);
    changeButtonText('Зарегистрироваться');
  };

  const onToggleAuth = () => {
    toggleAuthReg(false);
    changeButtonText('Войти');
  };

  const onChangeEmail = (e) => {
    changeEmail(e.currentTarget.value);
  };

  const onChangePassword = (e) => {
    changePassword(e.currentTarget.value);
  };

  const onChangeRepeatPassword = (e) => {
    changeRepeatPassword(e.currentTarget.value);
  };

  const sendForm = () => {
    if (reg) {
      const userData = {
        email,
        password,
        password2: repeatPassword,
      };
      changeButtonText('Отправка..');
      AjaxService.ajaxAuth(`/users/`, userData)
        .then(() => {
          message.success('Вы успешно зарегистрировались');
          // eslint-disable-next-line no-shadow
          const userData = {
            email,
            password,
          };
          return AjaxService.ajaxAuth(`/token/`, userData);
        })
        .then((res) => {
          props.responseHandler(res);
          changeEmail('');
          changePassword('');
          changeRepeatPassword('');
          changeButtonText('Зарегистироваться');
          props.openModalHandler();
        })
        .catch((error) => {
          const properties = Object.keys(error);
          properties.forEach((item) => {
            if (item === 'detail') {
              message.error(error[item]);
            } else {
              message.error(error[item][0]);
            }
          });
          changeButtonText('Зарегистироваться');
        });
    } else if (!reg) {
      const userData = {
        email,
        password,
      };
      changeButtonText('Отправка..');
      AjaxService.ajaxAuth(`/token/`, userData)
        .then((res) => {
          props.responseHandler(res);
          changeEmail('');
          changePassword('');
          changeButtonText('Войти');
          props.openModalHandler();
        })
        .catch((error) => {
          const properties = Object.keys(error);
          properties.forEach((item) => {
            if (item === 'detail') {
              message.error(error[item]);
            } else {
              message.error(error[item][0]);
            }
          });
          changeButtonText('Войти');
        });
    }
  };

  return (
    <Modal
      centered
      visible={props.open}
      footer={null}
      width={400}
      onCancel={() => {
        props.openModalHandler();
        onToggleAuth();
      }}
    >
      <ModalAuthHeader>
        {!reg && <p>Вход</p>}
        {reg && <p>Регистрация</p>}
      </ModalAuthHeader>
      <ModalAuthInputsBlock>
        <p>
          <Label>{reg ? 'введите e-mail:' : 'e-mail:'}</Label>
          <Input type='email' value={email} onChange={onChangeEmail} />
        </p>
        <p>
          <Label>{reg ? 'введите пароль:' : 'пароль:'}</Label>
          <Input type='password' value={password} onChange={onChangePassword} />
        </p>
        {reg && (
          <p>
            <Label>введите пароль еще раз:</Label>
            <Input
              type='password'
              value={repeatPassword}
              onChange={onChangeRepeatPassword}
            />
          </p>
        )}
      </ModalAuthInputsBlock>
      <ModalAuthButton>
        {/* eslint-disable-next-line react/button-has-type */}
        <button onClick={sendForm}>{buttonText}</button>
      </ModalAuthButton>
      {!reg && (
        <RegButtonText>
          Еще не зарегистрированы?{' '}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span onClick={onToggleReg}>Регистрация</span>
        </RegButtonText>
      )}
    </Modal>
  );
}

export default ModalAuth;
