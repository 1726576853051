import { connect } from 'react-redux';
// eslint-disable-next-line import/named
import { useHistory } from 'react-router-dom';
import { setCurrentCertificateData } from '../../actions/user';

function LinkToCertificate({
  type,
  html,
  value,
  onSetCurrentCertificateData,
  children,
}) {
  const history = useHistory();
  const onToCertificateGenerator = () => {
    const data = {
      type,
      html,
      value,
    };
    onSetCurrentCertificateData(data);
    history.push('/certificate');
  };
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div style={{ cursor: 'pointer' }} onClick={onToCertificateGenerator}>
      {children}
    </div>
  );
}

export default connect(null, (dispatch) => ({
  onSetCurrentCertificateData: (data) => {
    dispatch(setCurrentCertificateData(data));
  },
}))(LinkToCertificate);
