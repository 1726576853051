let API_SERVER_VAL = '';

switch (process.env.REACT_APP_DEPLOY_ENV) {
  case 'dev':
    API_SERVER_VAL = 'https://rmkedu.dev.wwhw.org/api/v1';
    break;
  case 'stage':
    API_SERVER_VAL = 'https://e-univer.ru/api/v1';
    break;
  case 'master':
    API_SERVER_VAL = 'https://e-univer.ru/api/v1';
    break;
  default:
    API_SERVER_VAL = 'https://rmkedu.dev.wwhw.org/api/v1';
    break;
}

export const baseURL = API_SERVER_VAL;
export const defaultLimit = 20;
