import { connect } from 'react-redux';
import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { getTopicServer } from '../../actions/ajaxMiddleware';
import Header from '../Header/Header';
import { Main } from '../../shared/Main';
import { Container } from '../../shared/Container';
import Footer from '../Footer/Footer';
import { setCurrentTopic } from '../../actions/tests';
import {
  TopicDescription,
  AccessTopicBlock,
  TopicHeaderBlock,
  StepsBlock,
  AccordionStepBlock,
} from './style';
import importantIcon from '../../img/icon/important-big.svg';
import arrowLong from '../../img/icon/arrow-long.svg';
import AccordionHeader from './AccordionHeader';
import AccordionContent from './AccordionContent';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import ModalAlert from '../ModalAlert/ModalAlert';
import examIcon from '../../img/icon/topic-exam.svg';
import { TopicExamBlock, TopicExamButtonBlock } from '../TopicsPage/style';
import { ButtonActive, ButtonUnActive } from '../../shared/Buttons';

function TopicsItemPage(props) {
  const [blockTopicText, setBlockTopicText] = useState(null);
  const [selectedAccordId, setSelectedAccordId] = useState([]);
  const [confirmModalData, onConfirmModalData] = useState({
    open: false,
    title: 'Начать прохождение шага?',
    content: '',
    test: '',
    id: '',
  });
  const [alertModalData, onAlertModalData] = useState({
    open: false,
    title: '',
    content: '',
  });
  const { onGetTopicServer } = props;
  const { onSetCurrentTopicData } = props;
  const { currentTopic } = props.tests;
  const { id } = props.match.params;

  const onOpenConfirm = (e) => {
    const start = Number(e.currentTarget.dataset.start);
    // eslint-disable-next-line no-shadow
    const { id } = e.currentTarget;
    const { test } = e.currentTarget.dataset;
    const { content } = e.currentTarget.dataset;
    if (start === 1) {
      // eslint-disable-next-line no-shadow
      const confirmModalData = {
        open: true,
        title:
          test === 'step'
            ? 'Начать прохождение шага?'
            : 'Начать тест на знание темы?',
        content,
        test,
        id,
      };
      onConfirmModalData(confirmModalData);
    } else {
      const alertData = {
        open: true,
        title: '',
        content:
          test === 'step'
            ? 'Прохождение шага будет доступно после прохождения предыдущего шага'
            : 'Прохождение теста по теме будет доступно после прохождения всех шагов',
      };
      onAlertModalData(alertData);
    }
  };

  const onCloseConfirm = () => {
    onConfirmModalData({ ...confirmModalData, open: !confirmModalData.open });
  };

  const onCloseAlert = () => {
    onAlertModalData({ ...alertModalData, open: !alertModalData.open });
  };

  useEffect(() => {
    onGetTopicServer(id);
    return onSetCurrentTopicData({ topic: null, steps: null });
  }, [onGetTopicServer, onSetCurrentTopicData, id]);

  const makeBlockText = useCallback(
    // eslint-disable-next-line no-shadow
    (currentTopic) => {
      let noOne = false;
      let one = false;
      let text = null;
      if (currentTopic.open_after.length > 0) {
        for (let i = 0; i < currentTopic.open_after.length; i += 1) {
          if (!currentTopic.open_after[i].is_taken_by_user) {
            if (text === null && !one) {
              text = `Данная тема будет доступна после окончания темы: "${currentTopic.open_after[i].title}"`;
              one = currentTopic.open_after[i].title;
            } else if (one && !noOne) {
              text = `Данная тема будет доступна после окончания тем: "${one}", "${currentTopic.open_after[i].title}"`;
              noOne = true;
            } else if (noOne) {
              text += `, "${currentTopic.open_after[i].title}"`;
            }
          }
        }
        setBlockTopicText(text);
      } else {
        setBlockTopicText(null);
      }
    },
    [setBlockTopicText]
  );

  useEffect(() => {
    if (currentTopic !== null) {
      makeBlockText(currentTopic);
    }
  }, [currentTopic, makeBlockText]);

  return (
    <div className='wrapper'>
      <Header />
      <Main>
        {currentTopic !== null ? (
          <>
            <Container>
              <TopicHeaderBlock>
                <Link to='/topics'>
                  <img src={arrowLong} alt='' /> назад к списку тем
                </Link>
                <div>Вы изучаете тему:</div>
                <h1 className='main-header'>{currentTopic.title}</h1>
              </TopicHeaderBlock>
            </Container>
            <Container>
              <TopicDescription>{currentTopic.description}</TopicDescription>
              {blockTopicText !== null ? (
                <AccessTopicBlock>
                  <div>
                    <img src={importantIcon} alt='' />
                  </div>
                  <div>{blockTopicText}</div>
                </AccessTopicBlock>
              ) : (
                ''
              )}
            </Container>
            <Container>
              <StepsBlock>
                <h2>Список шагов</h2>
                <Accordion
                  allowMultipleExpanded={false}
                  allowZeroExpanded
                  // eslint-disable-next-line no-shadow
                  onChange={(id) => setSelectedAccordId(id)}
                >
                  {props.tests.currentTopicSteps.map((item) => (
                    <AccordionStepBlock>
                      <AccordionItem
                        uuid={props.tests.currentTopicSteps.indexOf(item) + 1}
                        key={item.id}
                      >
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <AccordionHeader
                              selectedAccordId={selectedAccordId}
                              stepInfo={item}
                              accordId={
                                props.tests.currentTopicSteps.indexOf(item) + 1
                              }
                            />
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <AccordionContent
                            numberStep={
                              props.tests.currentTopicSteps.indexOf(item) + 1
                            }
                            stepInfo={item}
                            blockTopicText={blockTopicText}
                            openConfirm={onOpenConfirm}
                          />
                        </AccordionItemPanel>
                      </AccordionItem>
                    </AccordionStepBlock>
                  ))}
                </Accordion>
              </StepsBlock>
              <TopicExamBlock>
                <img src={examIcon} />
                <TopicExamButtonBlock>
                  <h3>После прохождения всех шагов пройдите тест </h3>
                  {blockTopicText === null && currentTopic.is_taken_by_user ? (
                    <ButtonActive
                      data-test='topic'
                      data-content={currentTopic.title}
                      id={currentTopic.id}
                      data-start={currentTopic.is_taken_by_user ? 1 : 0}
                      onClick={onOpenConfirm}
                      style={{ width: 310, height: 70 }}
                    >
                      Пройти тест
                    </ButtonActive>
                  ) : (
                    <ButtonUnActive style={{ width: 310, height: 70 }}>
                      Пройти тест
                    </ButtonUnActive>
                  )}
                  {!currentTopic.is_taken_by_user && (
                    <span>Пройдите все шаги чтобы открыть доступ к тесту</span>
                  )}
                </TopicExamButtonBlock>
              </TopicExamBlock>
              <ModalConfirm
                data={confirmModalData}
                open={confirmModalData.open}
                onClose={onCloseConfirm}
              />
              <ModalAlert
                open={alertModalData.open}
                title={alertModalData.title}
                content={alertModalData.content}
                onClose={onCloseAlert}
              />
            </Container>
          </>
        ) : (
          ''
        )}
      </Main>
      <Footer />
    </div>
  );
}

export default connect(
  (state) => ({
    user: state.user,
    tests: state.tests,
  }),
  (dispatch) => ({
    onGetTopicServer: (data) => {
      dispatch(getTopicServer(data));
    },
    onSetCurrentTopicData: (data) => {
      dispatch(setCurrentTopic(data));
    },
  })
)(TopicsItemPage);
