import { baseURL } from '../utils/api';

export class AjaxService {
  static ajaxAuth(paramUrl, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const response = await fetch(baseURL + paramUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const result = await response.json();
        resolve(result);
      } else {
        const result = await response.json();
        reject(result);
      }
    });
  }

  static ajaxPatch(paramUrl, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const response = await fetch(baseURL + paramUrl, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const result = await response.json();
        resolve(result);
      } else {
        const result = await response.json();
        reject(result);
      }
    });
  }

  static ajaxPost(paramUrl, data) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const response = await fetch(baseURL + paramUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const result = await response.json();
        resolve(result);
      } else {
        const result = await response.json();
        reject(result);
      }
    });
  }

  static ajaxGet(paramUrl) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const response = await fetch(baseURL + paramUrl, {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (response.ok) {
        const result = await response.json();
        resolve(result);
      } else {
        const result = await response.json();
        reject(result);
      }
    });
  }

  static refreshToken() {
    const data = {
      refresh: localStorage.getItem('refresh'),
    };
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const response = await fetch(`${baseURL}/token/refresh/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const result = await response.json();
        resolve(result);
      } else {
        const result = await response.json();
        reject(result);
      }
    });
  }
}
