import {
  ConvenientWrapper,
  ConvenientItem,
  ConvenientBlock,
  ConvenientItemHeader,
  ConvenientItemDescription,
  ConvenientItemNumber,
  ConvenientItemReverse,
  ConvenientBg,
} from './style';
import { Container } from '../../../shared/Container';
import bg from '../../../img/main-page/advan-bg.svg';

function Convenient() {
  return (
    <ConvenientWrapper>
      <Container>
        <h2>
          Почему с нами <span>удобно?</span>
        </h2>
        <ConvenientBg>
          <img src={bg} />
        </ConvenientBg>
        <ConvenientBlock>
          <ConvenientItem>
            <ConvenientItemNumber>1</ConvenientItemNumber>
            <div>
              <ConvenientItemHeader>
                Высокая эффективность и быстрый прогресс
              </ConvenientItemHeader>
              <ConvenientItemDescription>
                Наша цель – помочь вам самостоятельно повысить свой
                профессиональный уровень, укрепить уверенность в своих силах,
                повысить мотивацию и добиться самореализации и наилучших
                карьерных возможностей.
              </ConvenientItemDescription>
            </div>
          </ConvenientItem>
          <ConvenientItemReverse>
            <ConvenientItemNumber>2</ConvenientItemNumber>
            <div>
              <ConvenientItemHeader>
                Легкость и комфорт самообразования
              </ConvenientItemHeader>
              <ConvenientItemDescription>
                Короткие задания для самостоятельного обучения легко выполнять.
                Вы сами выбираете, когда выполнять задания, где заниматься,
                какое задание выполнять.
              </ConvenientItemDescription>
            </div>
          </ConvenientItemReverse>
          <ConvenientItem>
            <ConvenientItemNumber>3</ConvenientItemNumber>
            <div>
              <ConvenientItemHeader>
                Не нужны первоначальные знания
              </ConvenientItemHeader>
              <ConvenientItemDescription>
                Задание короткие, не требуют от вас первоначальных знаний. Вам
                предлагаются варианты ответов. На каждом этапе выполнения есть
                подсказки.
              </ConvenientItemDescription>
            </div>
          </ConvenientItem>
          <ConvenientItemReverse>
            <ConvenientItemNumber>4</ConvenientItemNumber>
            <div>
              <ConvenientItemHeader>
                Сертификаты за достигнутые успехи
              </ConvenientItemHeader>
              <ConvenientItemDescription>
                Во время обучения вы сможете участвовать в гонке за
                сертификатами
              </ConvenientItemDescription>
            </div>
          </ConvenientItemReverse>
        </ConvenientBlock>
      </Container>
    </ConvenientWrapper>
  );
}

export default Convenient;
