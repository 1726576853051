class OtherService {
  static isEmpty(obj) {
    // eslint-disable-next-line guard-for-in,no-restricted-syntax,no-unreachable-loop
    for (const key in obj) {
      return false;
    }
    return true;
  }

  static msToTimeMinutes(duration) {
    let minutes = parseInt(duration / (1000 * 60), 10);
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return minutes;
  }

  static msToTimeSeconds(duration) {
    let seconds = parseInt((duration / 1000) % 60, 10);
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    return seconds;
  }

  static millisToMinutesAndSeconds(millis) {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return `${minutes} минут, ${seconds < 10 ? '0' : ''}${seconds} секунд`;
  }

  static sortByField(field) {
    return (a, b) => (a[field] > b[field] ? 1 : -1);
  }

  static onLevelStyle(currentLevel, levels) {
    let currentLevelStyled = currentLevel;
    const separate = Math.ceil(levels.length / 4);
    if (currentLevel) {
      switch (true) {
        case currentLevel.degree < separate: {
          currentLevelStyled.fill = '#E4E4E4';
          currentLevelStyled.color = '#2E2E2E';
          break;
        }
        case currentLevel.degree === separate ||
          currentLevel.degree < separate * 2: {
          currentLevelStyled.fill = '#FBFDAE';
          currentLevelStyled.color = '#2E2E2E';
          break;
        }
        case currentLevel.degree === separate * 2 ||
          currentLevel.degree < separate * 3: {
          currentLevelStyled.fill = '#B2F3A0';
          currentLevelStyled.color = '#2E2E2E';
          break;
        }
        case currentLevel.degree === separate * 3 ||
          currentLevel.degree < separate * 4: {
          currentLevelStyled.fill = '#81bf6c';
          currentLevelStyled.color = '#2E2E2E';
          break;
        }
        default: {
          currentLevelStyled.fill = '#4DA550';
          currentLevelStyled.color = '#FFF';
        }
      }
    } else {
      currentLevelStyled = {};
      currentLevelStyled.fill = '#E4E4E4';
      currentLevelStyled.color = '#2E2E2E';
    }
    return currentLevelStyled;
  }
}

export default OtherService;
