import styled from 'styled-components';

export const AlertBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 80px;
  @media (max-width: 550px) {
    padding: 40px;
  }
`;

export const AlertTitle = styled.div`
  width: 100%;
  text-align: center;
  font-family: Manrope-ExtraBold;
  font-weight: 800;
  font-size: 30px;
`;

export const AlertContent = styled.div`
  font-family: Manrope-ExtraBold;
  font-size: 30px;
  max-width: 500px;
  text-align: center;
  margin-bottom: 30px;
  @media (max-width: 550px) {
    font-size: 22px;
  }
`;

export const PromptContent = styled.div`
  font-size: 22px;
  text-align: center;
  margin-bottom: 30px;
`;

export const AlertButtonBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  button {
    font-size: 20px;
    width: 300px;
    background-color: #abcc3d;
    border-radius: 50px;
    height: 70px;
    border: none;
    outline: none;
    cursor: pointer;
  }
`;
