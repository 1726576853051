import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { useEffect, useCallback, useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { userHandlerAuth } from '../../actions/user';
import checkStatus from '../../img/icon/pass.svg';
import noCheckStatus from '../../img/icon/notpass.svg';
import examIcon from '../../img/icon/exam.svg';
import { Main } from '../../shared/Main';
import { Container } from '../../shared/Container';
import { getThemesServer } from '../../actions/ajaxMiddleware';
import {
  List,
  ListItem,
  ListItemStatus,
  ListItemStatusBlock,
  TopicBlock,
  TopicExamBlock,
  MobileStatus,
  TopicExamButtonBlock,
} from './style';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import ModalAlert from '../ModalAlert/ModalAlert';
import {
  ButtonActive,
  ButtonRepeat,
  ButtonUnActive,
} from '../../shared/Buttons';

function TopicsPage(props) {
  const [themes, setThemes] = useState([]);
  const [accessToExam, setAccessToExam] = useState(false);
  const { onGetThemesServer } = props;
  const themesData = props.tests.themes;
  const { levels } = props.tests;
  const canStartExam = props.user.can_start_exam_test;

  const [confirmModalData, onConfirmModalData] = useState({
    open: false,
    title: 'Начать прохождение шага?',
    content: '',
    test: '',
    id: '',
  });
  const [alertModalData, onAlertModalData] = useState({
    open: false,
    title: '',
    content: '',
  });

  const onOpenConfirm = (e) => {
    const start = Number(e.currentTarget.dataset.start);
    const { id } = e.currentTarget;
    const { test } = e.currentTarget.dataset;
    const { content } = e.currentTarget.dataset;
    if (start === 1) {
      switch (test) {
        case 'topic': {
          // eslint-disable-next-line no-shadow
          const confirmModalData = {
            open: true,
            title: 'Начать тест на знание темы?',
            content,
            test,
            id,
          };
          onConfirmModalData(confirmModalData);
          break;
        }
        default: {
          // eslint-disable-next-line no-shadow
          const confirmModalData = {
            open: true,
            title: 'Начать тест по всем темам?',
            content,
            test,
            id,
          };
          onConfirmModalData(confirmModalData);
        }
      }
    } else {
      const alertData = {
        open: true,
        title: '',
        content:
          'Прохождение теста по теме будет доступно после прохождения всех шагов',
      };
      onAlertModalData(alertData);
    }
  };

  const onCloseConfirm = () => {
    onConfirmModalData({ ...confirmModalData, open: !confirmModalData.open });
  };

  const onCloseAlert = () => {
    onAlertModalData({ ...alertModalData, open: !alertModalData.open });
  };

  useEffect(() => {
    if (levels.length > 0) {
      onGetThemesServer(levels);
    }
  }, [onGetThemesServer, levels]);

  useEffect(() => {
    setAccessToExam(canStartExam);
  }, [canStartExam, setAccessToExam]);

  const makeThemesList = useCallback(
    // eslint-disable-next-line no-shadow
    (themesData) => {
      const list = [];
      themesData.forEach((item) => {
        const topicInfo = { topics: [] };
        topicInfo.level = <h2 key={item.levelTitle}>{item.levelTitle}</h2>;
        item.topics.forEach((topic) => {
          let img = noCheckStatus;
          let text = 'Тема не пройдена';
          let passed = false;
          if (topic.is_passed_by_user && topic.is_taken_by_user) {
            img = checkStatus;
            text = 'Тема сдана';
            passed = true;
          } else if (topic.is_taken_by_user) {
            img = checkStatus;
            text = 'Тема пройдена';
          }
          const listItem = (
            <ListItem key={topic.pk}>
              <MobileStatus>
                <div>
                  <img src={img} alt='' />
                </div>
                <Link to={`/topic/${topic.pk}`}>{topic.title}</Link>
              </MobileStatus>
              <ListItemStatusBlock>
                <ListItemStatus>
                  <img src={img} alt='' />
                  {text}
                </ListItemStatus>
                {passed && (
                  <ButtonRepeat
                    data-test='topic'
                    data-content={topic.title}
                    id={topic.pk}
                    data-start={topic.is_taken_by_user ? 1 : 0}
                    onClick={onOpenConfirm}
                  >
                    Пройти eще раз
                  </ButtonRepeat>
                )}
                {!passed && topic.is_taken_by_user && (
                  <ButtonActive
                    data-test='topic'
                    data-content={topic.title}
                    id={topic.pk}
                    data-start={topic.is_taken_by_user ? 1 : 0}
                    onClick={onOpenConfirm}
                  >
                    Пройти тему
                  </ButtonActive>
                )}
                {!passed && !topic.is_taken_by_user && (
                  <ButtonUnActive
                    data-test='topic'
                    data-content={topic.title}
                    id={topic.pk}
                    data-start={topic.is_taken_by_user ? 1 : 0}
                    onClick={onOpenConfirm}
                  >
                    Пройти тему
                  </ButtonUnActive>
                )}
              </ListItemStatusBlock>
            </ListItem>
          );
          topicInfo.topics.push(listItem);
        });
        list.push(topicInfo);
      });
      setThemes(list);
    },
    [setThemes]
  );

  useEffect(() => {
    if (themesData.length > 0) {
      makeThemesList(themesData);
    }
  }, [themesData, makeThemesList]);

  return (
    <div className='wrapper'>
      <Header />
      <Main>
        <Container>
          <h1 className='main-header'>Список тем</h1>
          <List>
            {themes.map((item) => (
              <TopicBlock>
                {item.level}
                {item.topics.map((topic) => topic)}
              </TopicBlock>
            ))}
            {themes.length > 0 && (
              <TopicExamBlock>
                <img src={examIcon} />
                <TopicExamButtonBlock>
                  <h3>После прохождения всех тем сдайте зкзамен</h3>
                  {!accessToExam ? (
                    <ButtonUnActive style={{ width: 310, height: 70 }}>
                      Сдать экзамен
                    </ButtonUnActive>
                  ) : (
                    <ButtonActive
                      data-test='exam'
                      data-content='Экзамен'
                      id={1}
                      data-start={accessToExam ? 1 : 0}
                      onClick={onOpenConfirm}
                      style={{ width: 310, height: 70 }}
                    >
                      Сдать экзамен
                    </ButtonActive>
                  )}
                  {!accessToExam && (
                    <span>
                      Пройдите все темы чтобы открыть доступ к экзамену{' '}
                    </span>
                  )}
                </TopicExamButtonBlock>
              </TopicExamBlock>
            )}
          </List>
        </Container>
        <ModalConfirm
          data={confirmModalData}
          open={confirmModalData.open}
          onClose={onCloseConfirm}
        />
        <ModalAlert
          open={alertModalData.open}
          title={alertModalData.title}
          content={alertModalData.content}
          onClose={onCloseAlert}
        />
      </Main>
      <Footer />
    </div>
  );
}

export default connect(
  (state) => ({
    user: state.user,
    tests: state.tests,
  }),
  (dispatch) => ({
    onUserHandlerAuth: (data) => {
      dispatch(userHandlerAuth(data));
    },
    onGetThemesServer: (data) => {
      dispatch(getThemesServer(data));
    },
  })
)(TopicsPage);
