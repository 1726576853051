export const sortField = [
  {
    field: 'first_name',
    title: 'Имя',
    edit: true,
  },
  {
    field: 'middle_name',
    title: 'Отчество',
    edit: true,
  },
  {
    field: 'last_name',
    title: 'Фамилия',
    edit: true,
  },
  {
    field: 'region',
    title: 'Регион',
    edit: true,
  },
  {
    field: 'workplace',
    title: 'Место работы',
    edit: true,
  },
  {
    field: 'position',
    title: 'Должность',
    edit: true,
  },
  {
    field: 'email',
    title: 'E-mail',
    edit: false,
  },
  {
    field: 'phone_number',
    title: 'Телефон',
    edit: true,
  },
  {
    field: 'password',
    title: 'Пароль',
    edit: true,
  },
  {
    field: 'level',
    title: 'Уровень',
    edit: false,
  },
  {
    field: 'certificate',
    title: 'Сертификат',
    edit: false,
  },
];
