import styled from 'styled-components';
import { headerHeight } from '../components/Header/style';
import { footerHeight } from '../components/Footer/style';

export const Main = styled.main`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - ${headerHeight} - ${footerHeight});
  margin-bottom: ${footerHeight};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 550px) {
    margin-top: 40px;
  }
`;

export const MainIndex = styled.main`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - ${headerHeight} - ${footerHeight});
  margin-bottom: ${footerHeight};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
