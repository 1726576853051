export const setTestsData = (data) => ({ type: 'SET_TESTS_DATA', data });
export const setThemes = (data) => ({ type: 'SET_THEMES_DATA', data });
export const setCurrentTopic = (data) => ({
  type: 'SET_CURRENT_TOPIC_DATA',
  data,
});
export const setLevelsData = (data) => ({ type: 'SET_LEVELS_DATA', data });
export const setCurrentTestData = (data) => ({
  type: 'SET_CURRENT_TEST_DATA',
  data,
});
export const setNewQuestionTestData = (data) => ({
  type: 'SET_NEW_QUESTION_TEST_DATA',
  data,
});
export const setNewQuestionTestEnd = () => ({ type: 'SET_NEW_QUESTION_END' });
export const resetCurrentTestData = () => ({ type: 'RESET_CURRENT_TEST_DATA' });
