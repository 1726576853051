import { useState } from 'react';
import { TooltipBlock } from './style';
import ModalConfirm from '../ModalConfirm/ModalConfirm';

function TooltipContent(props) {
  const [openConfirm, onOpenConfirm] = useState(false);

  const onStartTest = () => {
    if (props.startLevel !== null) {
      onOpenConfirm(!openConfirm);
    }
  };

  const onCloseConfirm = () => {
    onOpenConfirm(!openConfirm);
  };

  return (
    <TooltipBlock
      style={{ cursor: props.startLevel === null ? '' : 'pointer' }}
      onClick={onStartTest}
    >
      <div>{props.startLevel !== null && 'Доступен тест на новый уровень'}</div>
      {props.startLevel !== null ? (
        <ModalConfirm
          data={{
            title: 'Начать тест на уровень?',
            content: props.startLevel.title,
            test: 'level',
            id: props.startLevel.pk,
          }}
          open={openConfirm}
          onClose={onCloseConfirm}
        />
      ) : (
        ''
      )}
    </TooltipBlock>
  );
}

export default TooltipContent;
