import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.user.userAuth ? <Component {...props} /> : <Redirect to='/' />
      }
    />
  );
}

export default connect((state) => ({
  user: state.user,
}))(PrivateRoute);
