import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import MainPage from './MainPage/MainPage';
import TopicsPage from './TopicsPage/TopicsPage';
import TopicsItemPage from './TopicsItemPage/TopicsItemPage';
import TestPassPage from './TestPassPage/TestPassPage';
import CertificatePage from './CertificatePage/CertificatePage';
import { checkAuth } from '../actions/user';
import { getTestsServer } from '../actions/ajaxMiddleware';
import PrivateRoute from './PrivateRoute';
import ProfilePage from './ProfilePage/ProfilePage';
import History from './History/History';

function Routers(props) {
  const { onCheckAuth } = props;
  const { onGetTestsServer } = props;
  const { userAuth } = props.user;

  useEffect(() => {
    onCheckAuth();
  }, [onCheckAuth]);

  useEffect(() => {
    if (userAuth) {
      onGetTestsServer();
    }
  }, [userAuth, onGetTestsServer]);

  return (
    <Switch>
      <Route exact path='/' component={MainPage} />
      <PrivateRoute path='/certificate' component={CertificatePage} />
      <PrivateRoute
        user={props.user}
        exact
        path='/topics'
        component={TopicsPage}
      />
      <PrivateRoute
        user={props.user}
        exact
        path='/topic/:id'
        component={TopicsItemPage}
      />
      <PrivateRoute
        user={props.user}
        exact
        path='/test/:type/:id'
        component={TestPassPage}
      />
      <PrivateRoute
        user={props.user}
        exact
        path='/profile'
        component={ProfilePage}
      />
      <PrivateRoute
        user={props.user}
        exact
        path='/history'
        component={History}
      />
    </Switch>
  );
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) => ({
    onCheckAuth: () => {
      dispatch(checkAuth());
    },
    onGetTestsServer: () => {
      dispatch(getTestsServer());
    },
  })
)(Routers);
