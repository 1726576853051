import { withRouter } from 'react-router';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Container } from '../../shared/Container';
import './certificate.css';
import './animate.css';
// eslint-disable-next-line import/order
import { useLayoutEffect } from 'react';
import { createCertificate, savePDF } from './converter';
import { ButtonActive } from '../../shared/Buttons';
import { CertificateBlock } from './style';
// eslint-disable-next-line import/order
import { connect } from 'react-redux';

function CertificatePage({ user }) {
  useLayoutEffect(() => {
    createCertificate(
      user.currentCertificate.type,
      user.currentCertificate.value
    );
  }, []);
  return (
    <div className='wrapper'>
      <div id='layout'>
        <div>
          <div className='cssload-thecube'>
            <div className='cssload-cube cssload-c1' />
            <div className='cssload-cube cssload-c2' />
            <div className='cssload-cube cssload-c4' />
            <div className='cssload-cube cssload-c3' />
          </div>
        </div>
      </div>
      <div dangerouslySetInnerHTML={{ __html: user.currentCertificate.html }} />
      <Header />
      <Container>
        <CertificateBlock>
          <div id='certificate-img' />
          <ButtonActive style={{ marginBottom: 200 }} onClick={savePDF}>
            Загрузить PDF
          </ButtonActive>
        </CertificateBlock>
      </Container>
      <Footer />
    </div>
  );
}

export default withRouter(
  connect((state) => ({
    user: state.user,
  }))(CertificatePage)
);
