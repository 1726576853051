import { Modal } from 'antd';
import { withRouter } from 'react-router';
import { ConfirmBlock, ConfirmTitle, ConfirmButtonBlock } from './style';
import { ButtonActive, ButtonRepeat } from '../../shared/Buttons';

function ModalConfirm(props) {
  const onConfirm = () => {
    props.history.push(`/test/${props.data.test}/${props.data.id}`);
  };

  return (
    <Modal
      centered
      visible={props.open}
      footer={null}
      width={700}
      bodyStyle={{ overflow: 'hidden' }}
      onCancel={props.onClose}
    >
      <ConfirmBlock>
        {props.data.title}
        <ConfirmTitle>
          <span>{props.data.content}</span>
          <div />
        </ConfirmTitle>
        <ConfirmButtonBlock>
          <ButtonActive onClick={onConfirm} style={{ color: '#000' }}>
            Да
          </ButtonActive>
          <ButtonRepeat style={{ color: '#979797' }} onClick={props.onClose}>
            Нет
          </ButtonRepeat>
        </ConfirmButtonBlock>
      </ConfirmBlock>
    </Modal>
  );
}

export default withRouter(ModalConfirm);
