import moment from 'moment';
import { FooterStyled } from './style';

function Footer() {
  return (
    <FooterStyled>
      <p>e-univer</p>
      <p>RMK EDU</p>
      <p>{moment().year()}</p>
    </FooterStyled>
  );
}

export default Footer;
