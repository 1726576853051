import { connect } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import userIcon from '../../img/icon/user-new.svg';
import notificationIcon from '../../img/icon/notification.svg';
import ELogo from '../../img/icon/e-univer.svg';
import enterIcon from '../../img/icon/enter.svg';
import { Container } from '../../shared/Container';
import {
  HeaderStyled,
  HeaderUserBlock,
  Logo,
  HeaderContainer,
  HeaderUserButton,
  HeaderUserButtonMobile,
  Notification,
  Tooltip,
} from './style';
import TooltipContent from '../TooltipContent/TooltipContent';
import { getLevelsServer } from '../../actions/ajaxMiddleware';

function Header(props) {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [startLevel, setStartLevel] = useState(null);
  const tooltipRef = useRef(null);
  const [level, setLevel] = useState(null);
  const userLevel = props.user.level;
  const { levels } = props;

  useEffect(() => {
    if (userLevel !== null && levels.length > 0) {
      for (let i = 0; i < levels.length; i += 1) {
        if (
          levels[i].can_start_level_test &&
          Number(userLevel.degree) < Number(levels[i].degree)
        ) {
          setStartLevel(levels[i]);
          break;
        }
      }
    } else if (userLevel === null && levels.length > 0) {
      levels.sort((a, b) => (a.degree > b.degree ? 1 : -1));
      for (let i = 0; i < levels.length; i += 1) {
        if (levels[i].can_start_level_test) {
          setStartLevel(levels[i]);
          break;
        }
      }
    }
  }, [userLevel, levels, setTooltipVisible, setStartLevel, setLevel]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target) &&
        tooltipVisible
      ) {
        setTooltipVisible(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [tooltipRef, tooltipVisible]);

  const openProfile = () => {
    props.onGetLevelsServer();
    props.history.push(`/profile`);
  };

  const openTooltip = () => {
    if (startLevel) {
      setTooltipVisible(true);
    }
  };

  return (
    <HeaderStyled>
      <Container>
        <HeaderContainer>
          <a href='/'>
            <Logo>
              <img src={ELogo} alt='' />
            </Logo>
          </a>
          {!props.user.userAuth && (
            <>
              <HeaderUserButton onClick={props.onClickUserIcon}>
                <img src={enterIcon} alt='' />
                Войти
              </HeaderUserButton>
              <HeaderUserButtonMobile onClick={props.onClickUserIcon}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <img src={userIcon} alt='' />
              </HeaderUserButtonMobile>
            </>
          )}
          {props.user.userAuth && (
            <HeaderUserBlock>
              <div style={{ position: 'relative' }}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <img src={notificationIcon} alt='' onClick={openTooltip} />
                {tooltipVisible && (
                  <Tooltip ref={tooltipRef}>
                    <TooltipContent level={level} startLevel={startLevel} />
                  </Tooltip>
                )}
                {startLevel && <Notification />}
              </div>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <img onClick={openProfile} src={userIcon} alt='' />
              <span>
                {props.user.level ? props.user.level.title : 'Нет уровня'}
              </span>
            </HeaderUserBlock>
          )}
        </HeaderContainer>
      </Container>
    </HeaderStyled>
  );
}

export default withRouter(
  connect(
    (state) => ({
      user: state.user,
      levels: state.tests.levels,
    }),
    (dispatch) => ({
      onGetLevelsServer: () => {
        dispatch(getLevelsServer());
      },
    })
  )(Header)
);
