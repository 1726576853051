import { AccordionBlock } from './style';
import {
  ButtonActive,
  ButtonRepeat,
  ButtonUnActive,
} from '../../shared/Buttons';

function AccordionContent(props) {
  let canStartTest = true;
  if (props.stepInfo.open_after.length > 0) {
    const notPass = props.stepInfo.open_after.filter(
      (item) => item.is_passed_by_user === false
    );
    if (notPass.length > 0) {
      canStartTest = false;
    }
  }
  if (props.blockTopicText !== null) {
    canStartTest = false;
  }

  return (
    <AccordionBlock>
      <p>{props.stepInfo.description}</p>
      {/* eslint-disable-next-line react/button-has-type */}
      {!canStartTest && (
        <ButtonUnActive
          data-test='step'
          data-content={props.stepInfo.title}
          data-start={canStartTest ? 1 : 0}
          id={props.stepInfo.pk}
          onClick={props.openConfirm}
        >
          Пройти шаг
        </ButtonUnActive>
      )}
      {canStartTest && !props.stepInfo.is_passed_by_user && (
        <ButtonActive
          data-test='step'
          data-content={props.stepInfo.title}
          data-start={canStartTest ? 1 : 0}
          id={props.stepInfo.pk}
          onClick={props.openConfirm}
        >
          Пройти шаг
        </ButtonActive>
      )}
      {canStartTest && props.stepInfo.is_passed_by_user && (
        <ButtonRepeat
          data-test='step'
          data-content={props.stepInfo.title}
          data-start={canStartTest ? 1 : 0}
          id={props.stepInfo.pk}
          onClick={props.openConfirm}
        >
          Пройти еще раз
        </ButtonRepeat>
      )}
    </AccordionBlock>
  );
}

export default AccordionContent;
