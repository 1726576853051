import styled from 'styled-components';

export const ConvenientWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f6faff;
  position: relative;
  overflow: hidden;
  h2 {
    font-family: Manrope-ExtraBold;
    font-weight: 800;
    font-size: 40px;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 80px;
    span {
      color: #abcc3d;
    }
  }
  @media (max-width: 550px) {
    h2 {
      font-size: 26px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
`;

export const ConvenientBg = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: auto;
  top: 230px;
  z-index: 0;
  @media (max-width: 550px) {
    display: none;
  }
`;

export const ConvenientBlock = styled.section`
  position: relative;
  width: 1144px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 100px;
  z-index: 1;
  @media (max-width: 550px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ConvenientItem = styled.div`
  font-family: 'Manrope-Regular';
  font-weight: 600;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > div {
    width: 480px;
  }
  @media (max-width: 550px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

export const ConvenientItemReverse = styled(ConvenientItem)`
  justify-content: flex-end;
  @media (max-width: 550px) {
    justify-content: flex-start;
  }
`;

export const ConvenientItemNumber = styled.span`
  font-size: 80px;
  color: #abcc3d;
  font-weight: 700;
  margin-top: -20px;
  margin-right: 24px;
`;

export const ConvenientItemHeader = styled.div`
  font-family: Manrope-ExtraBold;
  font-weight: 800;
  font-size: 30px;
  @media (max-width: 550px) {
    hyphens: auto;
  }
`;

export const ConvenientItemDescription = styled.div`
  margin-top: 16px;
  font-size: 20px;
`;
