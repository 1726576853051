import { Modal } from 'antd';
import {
  PromptContent,
  AlertTitle,
  AlertBlock,
  AlertContent,
  AlertButtonBlock,
} from './style';

function ModalAlert({ open, onClose, title, content, variant = 'alert' }) {
  return (
    <Modal centered visible={open} footer={null} width={700} onCancel={onClose}>
      <AlertBlock>
        <AlertTitle>{title}</AlertTitle>
        {variant === 'alert' ? (
          <AlertContent>{content}</AlertContent>
        ) : (
          <PromptContent>{content}</PromptContent>
        )}
        <AlertButtonBlock>
          {/* eslint-disable-next-line react/button-has-type */}
          <button onClick={onClose}>
            {variant === 'alert' ? 'Понятно' : 'Спасибо'}
          </button>
        </AlertButtonBlock>
      </AlertBlock>
    </Modal>
  );
}

export default ModalAlert;
