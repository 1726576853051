import styled from 'styled-components';

export const footerHeight = '150px';

export const FooterStyled = styled.footer`
  width: 100%;
  height: ${footerHeight};
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #8fbaff;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p:first-child {
    font-family: 'Manrope-ExtraBold';
    text-transform: uppercase;
    font-weight: 800;
    font-size: 25px;
    text-align: center;
  }
  p {
    margin: 0;
  }
`;
