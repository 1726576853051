import styled from 'styled-components';

export const ConfirmBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: Manrope-ExtraBold;
  font-size: 30px;
  text-align: center;
  padding: 80px;
  margin-bottom: 30px;
  @media (max-width: 550px) {
    padding: 40px 0;
    font-size: 22px;
  }
`;

export const ConfirmTitle = styled.div`
  position: relative;
  width: 100%;
  margin: 45px 0 30px 0;
  padding: 22px;
  span {
    position: relative;
    z-index: 1;
  }
  div {
    width: 200%;
    height: 100%;
    background-color: #e5efff;
    position: absolute;
    left: -110px;
    top: 0;
    z-index: 0;
  }
`;

export const ConfirmButtonBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button {
    font-family: Manrope-Regular;
    font-size: 20px;
    height: 70px;
    width: 240px;
  }
`;

export const ConfirmButton = styled.button`
  //width: 75px;
  //border-radius: 18px;
  //border: none;
  //outline: none;
  //cursor: pointer;
`;
