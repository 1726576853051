export const getTestsServer = () => ({ type: 'GET_TESTS_SERVER' });
export const getThemesServer = (data) => ({ type: 'GET_THEMES_SERVER', data });
export const getTopicServer = (data) => ({ type: 'GET_TOPIC_SERVER', data });
export const getProfileServer = () => ({ type: 'GET_PROFILE_SERVER' });
export const getLevelsServer = () => ({ type: 'GET_LEVELS_SERVER' });
export const getTestServer = (data) => ({ type: 'GET_TEST_SERVER', data });
export const sendAnswerServer = (data) => ({
  type: 'SEND_ANSWER_SERVER',
  data,
});
