import { connect } from 'react-redux';
import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Header from '../Header/Header';
import { Main } from '../../shared/Main';
import { Container } from '../../shared/Container';
import LinkToCertificate from '../LinkToCertificate/LinkToCertificate';
import {
  ListProfile,
  ListProfileItem,
  LinksBlock,
  HeaderProfile,
  ListLevelBlock,
  ListProfileGrid,
  LevelBlock,
  CertificateLink,
  AccordionLevelsBlock,
  AccordionSection,
} from './style';
import Footer from '../Footer/Footer';
import editIcon from '../../img/icon/edit-new.svg';
import { ReactComponent as StarBigIcon } from '../../img/icon/star-big.svg';
import { ReactComponent as ExistIcon } from '../../img/icon/enter.svg';
import { sortField } from '../../utils/sortFieldProfile';
import ModalField from '../ModalField/ModalField';
import clipIcon from '../../img/icon/file-new.svg';
import AccordionHeader from './AccordionHeader';
import AccordionContent from './AccordionContent';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import ModalAlert from '../ModalAlert/ModalAlert';
import arrowLong from '../../img/icon/arrow-long.svg';
import OtherService from '../../services/OtherService';

function ProfilePage(props) {
  const profileData = props.user;
  const [profileList, setProfileList] = useState([]);
  const [currentField, setCurrentField] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAccordId, setSelectedAccordId] = useState([]);
  const [confirmModalData, onConfirmModalData] = useState({
    open: false,
    title: 'Начать прохождение шага?',
    content: '',
    test: '',
    id: '',
  });
  const [alertModalData, onAlertModalData] = useState({
    open: false,
    title: '',
    content: '',
  });

  const onOpenConfirm = (e) => {
    const start = Number(e.currentTarget.dataset.start);
    const { id } = e.currentTarget;
    const { test } = e.currentTarget.dataset;
    const { content } = e.currentTarget.dataset;
    if (start === 1) {
      // eslint-disable-next-line no-shadow
      const confirmModalData = {
        open: true,
        title: 'Начать тест на уровень?',
        content,
        test,
        id,
      };
      onConfirmModalData(confirmModalData);
    } else {
      const alertData = {
        open: true,
        title: '',
        content:
          'Прохождение теста будет доступно после прохождения тем входящих в уровень',
      };
      onAlertModalData(alertData);
    }
  };

  const onCloseConfirm = () => {
    onConfirmModalData({ ...confirmModalData, open: !confirmModalData.open });
  };

  const onCloseAlert = () => {
    onAlertModalData({ ...alertModalData, open: !alertModalData.open });
  };

  const exitProfile = () => {
    localStorage.clear();
    window.location.reload();
  };

  const onChangeField = (e) => {
    const field = e.currentTarget.id;
    // eslint-disable-next-line no-shadow
    const currentField = profileList.list.find((item) => item.field === field);
    setCurrentField(currentField);
    // eslint-disable-next-line no-use-before-define
    changeOpenModal();
  };

  const changeOpenModal = () => {
    setOpenModal(!openModal);
  };

  const makeProfileList = useCallback(
    // eslint-disable-next-line no-shadow
    (profileData) => {
      // eslint-disable-next-line no-shadow
      const profileList = {
        list: [],
        certificate: null,
        level: null,
        examPassed: false,
      };
      let itemList;
      sortField.forEach((field) => {
        itemList = field;
        switch (field.field) {
          case 'password':
            itemList.value = '********';
            profileList.list.push(itemList);
            break;
          case 'level': {
            const currentLevelStyled = OtherService.onLevelStyle(
              profileData.level,
              props.tests.levels
            );
            if (profileData.level === null) {
              currentLevelStyled.value = 'не получен';
            } else {
              currentLevelStyled.value = profileData.level.title;
            }
            profileList.level = currentLevelStyled;
            break;
          }
          case 'certificate':
            if (!profileData.certificate && !profileData.certificate_html) {
              itemList.value = 'не получен';
              itemList.getCertificate = false;
            } else {
              itemList.getCertificate = true;
              itemList.value = `получен 
                        ${moment(
                          profileData.exam_passed_at ||
                            profileData.level_passed_at
                        ).format('DD.MM.YYYY')}`;
              if (profileData.certificate_html) {
                itemList.certificateHtml = profileData.certificate_html;
              } else {
                itemList.link = profileData.certificate;
              }
            }
            profileList.certificate = itemList;
            break;
          default:
            itemList.value = profileData[field.field];
            profileList.list.push(itemList);
        }
      });
      if (profileData.exam_passed_at) {
        profileList.examPassed = true;
      }
      profileList.separatorNumber = Math.ceil(profileList.list.length / 2);
      setProfileList(profileList);
    },
    [setProfileList]
  );

  useEffect(() => {
    if (profileData.id) {
      makeProfileList(profileData);
    }
  }, [profileData, makeProfileList]);

  return (
    <div className='wrapper'>
      <Header />
      <Main>
        {profileList.list && profileList.list.length > 0 ? (
          <>
            <Container>
              <HeaderProfile>
                <Link to='/topics'>
                  <img src={arrowLong} alt='' /> назад к списку тем
                </Link>
                <h1 className='main-header'>Профиль пользователя</h1>
              </HeaderProfile>
              <ListLevelBlock>
                <ListProfile>
                  <h2>Личные данные</h2>
                  <ListProfileGrid>
                    <div>
                      {profileList.list
                        .slice(0, profileList.separatorNumber)
                        .map((item) => (
                          <ListProfileItem key={item.field}>
                            <span>{item.title}</span>
                            {item.field === 'password' ? (
                              <div id='field-password'>{item.value}</div>
                            ) : (
                              <div>{item.value}</div>
                            )}
                            {item.edit && (
                              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                              <img
                                alt=''
                                id={item.field}
                                onClick={onChangeField}
                                src={editIcon}
                              />
                            )}
                          </ListProfileItem>
                        ))}
                    </div>
                    <div>
                      {profileList.list
                        .slice(
                          profileList.separatorNumber,
                          profileList.list.length + 1
                        )
                        .map((item) => (
                          <ListProfileItem key={item.field}>
                            <span>{item.title}</span>
                            {item.field === 'password' ? (
                              <div id='field-password'>{item.value}</div>
                            ) : (
                              <div>{item.value}</div>
                            )}
                            {item.edit && (
                              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                              <img
                                alt=''
                                id={item.field}
                                onClick={onChangeField}
                                src={editIcon}
                              />
                            )}
                          </ListProfileItem>
                        ))}
                    </div>
                  </ListProfileGrid>
                </ListProfile>
                <LevelBlock>
                  <StarBigIcon style={{ fill: profileList.level.fill }} />
                  <span
                    id='level-title'
                    style={{ color: profileList.level.color }}
                  >
                    {profileList.level.value}
                  </span>
                  <div className='value-text'>
                    Уровень {profileList.level.value}
                  </div>
                  <span>Сертификат</span>
                  <div className='value-text'>
                    {profileList.certificate.value}
                  </div>
                  {profileList.certificate.getCertificate && (
                    <CertificateLink>
                      <img alt='' src={clipIcon} />
                      {profileList.certificate.link ? (
                        <a href={profileList.certificate.link}>
                          Скачать сертификат
                        </a>
                      ) : (
                        <LinkToCertificate
                          type={profileList.examPassed ? 'exam' : 'level'}
                          html={profileList.certificate.certificateHtml}
                          value={
                            profileList.examPassed
                              ? ''
                              : profileList.level.value
                          }
                        >
                          Скачать сертификат
                        </LinkToCertificate>
                      )}
                    </CertificateLink>
                  )}
                </LevelBlock>
              </ListLevelBlock>
              <AccordionLevelsBlock>
                <h2>Список уровней пользователя</h2>
                <Accordion
                  allowMultipleExpanded={false}
                  allowZeroExpanded
                  // eslint-disable-next-line no-shadow
                  onChange={(id) => setSelectedAccordId(id)}
                >
                  {props.tests.levels.map((item) => (
                    <AccordionSection>
                      <AccordionItem
                        uuid={props.tests.levels.indexOf(item) + 1}
                        key={item.id}
                      >
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <AccordionHeader
                              selectedAccordId={selectedAccordId}
                              levelInfo={item}
                              userLevel={props.user.level}
                              accordId={props.tests.levels.indexOf(item) + 1}
                            />
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <AccordionContent
                            numberLevel={props.tests.levels.indexOf(item) + 1}
                            levelInfo={item}
                            openConfirm={onOpenConfirm}
                          />
                        </AccordionItemPanel>
                      </AccordionItem>
                    </AccordionSection>
                  ))}
                </Accordion>
              </AccordionLevelsBlock>
              <LinksBlock>
                <ExistIcon style={{ fill: '#abcc3d' }} />
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div onClick={exitProfile}>Выйти из профиля</div>
              </LinksBlock>
            </Container>
            <ModalField
              dataField={currentField}
              open={openModal}
              onClose={changeOpenModal}
            />
            <ModalConfirm
              data={confirmModalData}
              open={confirmModalData.open}
              onClose={onCloseConfirm}
            />
            <ModalAlert
              open={alertModalData.open}
              title={alertModalData.title}
              content={alertModalData.content}
              onClose={onCloseAlert}
            />
          </>
        ) : (
          ''
        )}
      </Main>
      <Footer />
    </div>
  );
}

export default connect((state) => ({
  user: state.user,
  tests: state.tests,
}))(ProfilePage);
