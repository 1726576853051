import { put, takeEvery } from 'redux-saga/effects';
import { AjaxService } from '../services/AjaxService';
import OtherService from '../services/OtherService';
import {
  setTestsData,
  setThemes,
  setCurrentTopic,
  setLevelsData,
  setCurrentTestData,
  setNewQuestionTestData,
} from '../actions/tests';
// eslint-disable-next-line import/no-cycle
import { sagaMiddleware } from '../index';
import { setDataProfile } from '../actions/user';

function* mySaga() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery('GET_TESTS_SERVER', getTests);
  // eslint-disable-next-line no-use-before-define
  yield takeEvery('GET_THEMES_SERVER', getThemes);
  // eslint-disable-next-line no-use-before-define
  yield takeEvery('GET_TOPIC_SERVER', getTopic);
  // eslint-disable-next-line no-use-before-define
  yield takeEvery('GET_PROFILE_SERVER', getProfile);
  // eslint-disable-next-line no-use-before-define
  yield takeEvery('GET_LEVELS_SERVER', getLevels);
  // eslint-disable-next-line no-use-before-define
  yield takeEvery('GET_TEST_SERVER', getTest);
  // eslint-disable-next-line no-use-before-define
  yield takeEvery('SEND_ANSWER_SERVER', sendAnswer);
}

function* getTests() {
  const result = yield AjaxService.ajaxGet('/tests/')
    .then((res) => res.results)
    // eslint-disable-next-line consistent-return
    .catch((error) => {
      if (error.code) {
        if (error.code === 'token_not_valid') {
          AjaxService.refreshToken()
            .then((res) => {
              localStorage.setItem('token', res.access);
              sagaMiddleware.run(getTests);
            })
            .catch(() => {
              localStorage.clear();
              window.location.reload();
            });
          return null;
        }
      }
    });
  if (result !== null) {
    yield put(setTestsData(result));
    // eslint-disable-next-line no-use-before-define
    sagaMiddleware.run(getProfile);
  }
}

function* getTest(action) {
  const data = {
    typeof: action.data.type,
    [action.data.type]: action.data.id,
  };
  const test = yield AjaxService.ajaxPost('/tests/', data)
    .then((res) => res)
    .catch((error) => {
      if (error.code) {
        console.log(error);
      }
    });
  let param;
  let qCount = test.answer_count;
  switch (action.data.type) {
    case 'exam':
      // eslint-disable-next-line no-plusplus
      ++qCount;
      break;
    case 'level':
      param = `/levels/${action.data.id}/`;
      // eslint-disable-next-line no-plusplus
      ++qCount;
      break;
    case 'topic':
      param = `/topics/${action.data.id}/`;
      // eslint-disable-next-line no-plusplus
      ++qCount;
      break;
    default: {
      param = `/steps/${action.data.id}/`;
    }
  }
  if (action.data.type !== 'exam') {
    const info = yield AjaxService.ajaxGet(param)
      .then((res) => res)
      .catch((error) => {
        if (error.code) {
          console.log(error);
        }
      });
    yield (test.title = info.title);
  }

  yield (test.qCount = qCount);
  yield (test.motivation = null);
  yield (test.type = action.data.type);
  if (action.data.type === 'step') {
    yield (test.required_percentage = null);
  }
  yield put(setCurrentTestData(test));
}

function* sendAnswer(action) {
  const data = {
    selected_variant_pk: action.data.answer,
    question_pk: action.data.questionId,
  };
  const newQuestion = yield AjaxService.ajaxPost(
    `/tests/${action.data.testId}/answers/`,
    data
  )
    .then((res) => res)
    .catch((error) => {
      if (error.code) {
        console.log(error);
      }
    });
  yield put(setNewQuestionTestData(newQuestion));
}

function* getTopic(action) {
  const topic = yield AjaxService.ajaxGet(`/topics/${action.data}/`).then(
    (res) => res
  );
  const steps = yield AjaxService.ajaxGet(`/steps/?topic=${action.data}`).then(
    (res) => res.results
  );

  // eslint-disable-next-line no-use-before-define
  const stepInfos = yield getStepsInfo(steps);

  yield steps.forEach((step) => {
    const data = stepInfos.filter((item) => item.id === step.pk);
    // eslint-disable-next-line no-param-reassign
    step.open_after = data[0].open_after;
  });

  const data = {
    topic,
    steps,
  };
  yield put(setCurrentTopic(data));
}

async function getStepsInfo(steps) {
  const stepInfos = [];
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const index in steps) {
    // eslint-disable-next-line no-await-in-loop,no-use-before-define
    const stepInfo = await getStepInfo(steps[index].pk);
    stepInfos.push(stepInfo);
  }
  return stepInfos;
}

function getStepInfo(id) {
  return AjaxService.ajaxGet(`/steps/${id}/`).then((res) => res);
}

function* getProfile() {
  const result = yield AjaxService.ajaxGet('/user/profile/').then((res) => res);
  // eslint-disable-next-line no-use-before-define
  sagaMiddleware.run(getLevels);
  yield put(setDataProfile(result));
}

function* getLevels() {
  const levels = yield AjaxService.ajaxGet('/levels/').then(
    (res) => res.results
  );
  // eslint-disable-next-line no-use-before-define
  const levelsInfo = yield getActualInfoLevels(levels);

  yield levels.forEach((level) => {
    const data = levelsInfo.filter((item) => item.id === level.pk);
    if (data[0].count > 0) {
      const { results } = data[0];
      // eslint-disable-next-line no-param-reassign
      level.completed_at = results[results.length - 1].completed_at;
      // eslint-disable-next-line no-param-reassign
      level.certificate = results[results.length - 1].certificate;
      // eslint-disable-next-line no-param-reassign
      level.certificate_html = results[results.length - 1].certificate_html;
    } else {
      // eslint-disable-next-line no-param-reassign
      level.completed_at = null;
      // eslint-disable-next-line no-param-reassign
      level.certificate = null;
      // eslint-disable-next-line no-param-reassign
      level.certificate_html = null;
    }
  });
  yield levels.sort(OtherService.sortByField('degree'));
  yield put(setLevelsData(levels));
}

async function getActualInfoLevels(levels) {
  const levelInfos = [];
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const index in levels) {
    // eslint-disable-next-line no-await-in-loop,no-use-before-define
    const levelInfo = await getActualInfoLevel(levels[index].pk);
    levelInfo.id = levels[index].pk;
    levelInfos.push(levelInfo);
  }
  return levelInfos;
}

function getActualInfoLevel(id) {
  return AjaxService.ajaxGet(
    `/tests/?typeof=level&status=level_passed&ordering=created_at&level=${id}`
  ).then((res) => {
    if (res.count > res.results.length) {
      const offset = res.count - 1;
      return AjaxService.ajaxGet(
        `/tests/?typeof=level&status=level_passed&ordering=created_at&level=${id}&offset=${offset}`
        // eslint-disable-next-line no-shadow
      ).then((res) => res);
    }
    return res;
  });
}

function* getThemes(action) {
  const levels = action.data;
  // eslint-disable-next-line no-use-before-define
  const topicsInfo = yield getTopicsLevels(levels);
  yield topicsInfo.sort(OtherService.sortByField('levelDegree'));
  yield put(setThemes(topicsInfo));
}

async function getTopicsLevels(levels) {
  const topicsInfos = [];
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const index in levels) {
    const topicInfo = {};
    // eslint-disable-next-line no-use-before-define,no-await-in-loop
    topicInfo.topics = await getTopicLevel(levels[index].pk);
    topicInfo.levelId = levels[index].pk;
    topicInfo.levelTitle = levels[index].title;
    topicInfo.levelDegree = levels[index].degree;
    topicsInfos.push(topicInfo);
  }
  return topicsInfos;
}

function getTopicLevel(id) {
  return AjaxService.ajaxGet(`/topics/?level=${id}`).then((res) => res.results);
}

export default mySaga;
