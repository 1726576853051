import { message, Modal } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line import/named
import { ModalBlock, Input, ConfirmButtonBlock, Label } from './style';
import { AjaxService } from '../../services/AjaxService';
import { setDataProfile } from '../../actions/user';
import { ButtonActive, ButtonRepeat } from '../../shared/Buttons';

function ModalField(props) {
  const initialState = {
    value: '',
    // password: '',
    newPassword1: '',
    newPassword2: '',
  };

  const [values, changeValue] = useState(initialState);
  const [sendData, setSendData] = useState(false);

  const onChangeValue = (e) => {
    const { id } = e.currentTarget;
    const { value } = e.currentTarget;
    changeValue({ ...values, [id]: value });
  };

  const setNewValue = () => {
    let field;
    let data;
    if (props.dataField.field !== 'password') {
      field = props.dataField.field;
      data = {
        [field]: values.value,
      };
    } else {
      data = {
        password: values.newPassword1,
        password2: values.newPassword2,
      };
    }
    setSendData(true);
    AjaxService.ajaxPatch('/user/profile/', data)
      .then((res) => {
        props.onSetDataProfile(res);
        changeValue(initialState);
        props.onClose();
        message.success('Значение успешно изменено');
        setSendData(false);
      })
      .catch((error) => {
        setSendData(false);
        const properties = Object.keys(error);
        properties.forEach((item) => {
          if (item === 'detail') {
            message.error(error[item]);
          } else {
            message.error(error[item][0]);
          }
        });
      });
  };

  return (
    <Modal
      centered
      visible={props.open}
      footer={null}
      width={400}
      onCancel={props.onClose}
    >
      {props.dataField !== null ? (
        <ModalBlock>
          {props.dataField.field !== 'password' ? (
            <>
              <p id='title'>Введите новое значение</p>
              <p className='margin_bottom'>
                <Input
                  type='text'
                  id='value'
                  // placeholder={props.dataField.value}
                  onChange={onChangeValue}
                  value={values.value}
                />
              </p>
            </>
          ) : (
            <>
              <p id='title'>Сменить пароль</p>
              {/* <p> */}
              {/*    <Input */}
              {/*        type="password" */}
              {/*        id="password" */}
              {/*        placeholder="Текущий пароль" */}
              {/*        onChange={onChangeValue} */}
              {/*        value={values.password} */}
              {/*    /> */}
              {/* </p> */}
              <p className='margin_bottom'>
                <Input
                  type='password'
                  id='newPassword1'
                  onChange={onChangeValue}
                  value={values.newPassword1}
                />
                <Label>новый пароль</Label>
              </p>
              <p className='margin_bottom'>
                <Input
                  type='password'
                  id='newPassword2'
                  onChange={onChangeValue}
                  value={values.newPassword2}
                />
                <Label>введите пароль еще раз</Label>
              </p>
            </>
          )}
          {sendData ? (
            <p>отправка...</p>
          ) : (
            <ConfirmButtonBlock>
              <ButtonActive onClick={setNewValue}>Изменить</ButtonActive>
              <ButtonRepeat onClick={props.onClose}>Отмена</ButtonRepeat>
            </ConfirmButtonBlock>
          )}
        </ModalBlock>
      ) : (
        ''
      )}
    </Modal>
  );
}

export default connect(null, (dispatch) => ({
  onSetDataProfile: (data) => {
    dispatch(setDataProfile(data));
  },
}))(ModalField);
