import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

let imgData = null;

export const savePDF = () => {
  // eslint-disable-next-line new-cap
  const pdf = new jsPDF('l', 'pt', [1999, 1412]);
  pdf.addImage(`data:image/png;base64,${imgData}`, 'PNG', 0, 0, 1999, 1412);
  pdf.save('certificate.pdf');
};

const getScreenshotOfElement = (
  element,
  posX,
  posY,
  width,
  height,
  callback
) => {
  html2canvas(element, {
    width: 1999,
    height: 1412,
    taintTest: false,
    allowTaint: false,
    scale: 1,
  }).then((canvas) => {
    document.getElementsByClassName('certificate-template')[0].remove();
    document.getElementById('certificate-img').append(canvas);
    document.getElementById('layout').remove();
    const context = canvas.getContext('2d');
    const imageData = context.getImageData(0, 0, width, height).data;
    const outputCanvas = document.createElement('canvas');
    const outputContext = outputCanvas.getContext('2d');
    outputCanvas.width = width;
    outputCanvas.height = height;

    const idata = outputContext.createImageData(width, height);
    idata.data.set(imageData);
    outputContext.putImageData(idata, 0, 0);

    callback(outputCanvas.toDataURL().replace('data:image/png;base64,', ''));
  });
};

export const createCertificate = (type, value) => {
  if (document.getElementById('test-description')) {
    if (type === 'exam') {
      document.getElementById('test-description').innerText =
        'экзамен по всем темам';
    } else if (type === 'level') {
      document.getElementById(
        'test-description'
      ).innerText = `тест на уровень - ${value}`;
    }
  }

  if (document.getElementById('test-duration')) {
    const el = document.getElementById('test-duration');
    const durationFromServer = el.innerText;
    const durationArray = durationFromServer.split(':');
    el.innerText = `${durationArray[1]} мин. ${Math.round(
      Number(durationArray[2])
    )} сек.`;
  }

  getScreenshotOfElement(
    document.getElementById('certificate-v1'),
    0,
    0,
    1999,
    1412,
    (data) => {
      imgData = data;
    },
    type,
    value
  );
};
