import computerIcon from '../../../img/main-page/computer.svg';
import certificateIcon from '../../../img/main-page/certificate.svg';
import okIcon from '../../../img/main-page/okey.svg';
import {
  AdvantagesWrapper,
  AdvantagesBlock,
  AdvantagesItem,
  AdvantagesItemDescription,
} from './style';
import { Container } from '../../../shared/Container';

function Advantages() {
  return (
    <AdvantagesWrapper>
      <Container>
        <h2>
          Почему <span>вам понравится</span>
          <br />
          наш сервис
        </h2>
        <AdvantagesBlock>
          <AdvantagesItem>
            <div>
              <img src={computerIcon} alt='' />
            </div>
            <AdvantagesItemDescription>
              Самый удобный сервис для самообразования и корпоративного обучения
            </AdvantagesItemDescription>
          </AdvantagesItem>
          <AdvantagesItem>
            <div>
              <img src={certificateIcon} alt='' />
            </div>
            <AdvantagesItemDescription>
              Сертификат о самообразовании для вас, вашего резюме и
              работодателя!
            </AdvantagesItemDescription>
          </AdvantagesItem>
          <AdvantagesItem>
            <div>
              <img src={okIcon} alt='' />
            </div>
            <AdvantagesItemDescription>
              Вы занимаетесь эффективно и ненапряжно
            </AdvantagesItemDescription>
          </AdvantagesItem>
        </AdvantagesBlock>
      </Container>
    </AdvantagesWrapper>
  );
}

export default Advantages;
