import styled from 'styled-components';

export const ModalAuthHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  p {
    font-family: 'Manrope-ExtraBold';
    font-weight: 800;
    font-size: 30px;
    margin-top: 50px;
  }
`;

export const Input = styled.input`
  width: 300px;
  height: 50px;
  background-color: #f2f7ff;
  border: none;
  padding: 0 15px;
  :focus {
    outline: none;
    border: 1px solid #8fbaff;
    border-radius: 5px;
  }
`;

export const Label = styled.div`
  position: absolute;
  top: -25px;
  left: 0;
  border-radius: 5px;
  color: #979797;
`;

export const ModalAuthInputsBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    position: relative;
    width: 300px;
    margin-bottom: 50px;
  }
`;

export const ModalAuthButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  button {
    display: inline-block;
    min-width: 300px;
    font-size: 18px;
    height: 70px;
    color: #000;
    background-color: #abcc3d;
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
  }
`;

export const RegButtonText = styled.div`
  margin-top: 30px;
  text-align: center;
  span {
    color: #abcc3d;
    cursor: pointer;
    text-decoration: underline;
  }
`;
