import styled from 'styled-components';
import infoBg from '../../img/main-page/info-bg.png';
import infoBgMobile from '../../img/main-page/info-bg-mobile.png';

const buttonStyle = `
    color: #000;
    text-align: center;
    background-color: #ABCC3D;
    padding: 20px 56px;
    border-radius: 50px;
    border: none;
    outline:none;
    cursor: pointer;
    margin-top: 15px;
    white-space: nowrap;
    :hover {
        transition: 0.5s;
        background-color: #8DAD22;
    }
`;

export const BackGroundInfoBlock = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  background-image: url(${infoBg});
  background-size: cover;
  @media (max-width: 550px) {
    background-image: url(${infoBgMobile});
  }
`;

export const InfoImgBlock = styled.section`
  position: relative;
  height: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 35px;
  z-index: 1;
  @media (max-width: 550px) {
    height: auto;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Info = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h1 span {
    color: #abcc3d;
  }
  button {
    display: inline-block;
    ${buttonStyle}
  }
  @media (max-width: 550px) {
    width: 100%;
    h1 {
      margin-top: 30px;
      font-size: 30px;
      width: 100%;
    }
    button {
      margin-bottom: 420px;
    }
  }
`;

export const CheckList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  color: #000000;
  margin: 24px 0;
  img {
    margin-right: 20px;
  }
`;

export const ImgDesktop = styled.div`
  position: absolute;
  right: -15%;
  top: auto;
  width: 70%;
  line-height: 0;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 550px) {
    display: none;
    position: static;
    width: 100%;
  }
`;

export const ImgMobile = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  line-height: 0;
  z-index: 0;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 550px) {
    display: inline-block;
    width: 100%;
  }
`;
