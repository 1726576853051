import styled from 'styled-components';

export const TooltipBlock = styled.div`
  max-width: 220px;
  display: flex;
  flex-direction: row;
  margin: 0 5px 0 5px;
  line-height: normal;
  color: #fff;
  font-size: 16px;
  div {
    text-align: center;
    margin-left: 5px;
  }
`;
